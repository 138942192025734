import React, { useState, useEffect } from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Link, Box, Input, Button, Section } from "@quarkly/widgets";
import BusinessProfileForm from "./BusinessProfileForm";
import { useUser } from './FirebaseHooks';
import { useGetCompaniesData, useUpdateContactDataById } from './CrmUtils';
const defaultProps = {
	"padding": "120px 0 120px 0",
	"lg-margin": "0 0 0 0",
	"lg-padding": "80px 0 80px 0",
	"quarkly-title": "BusinessProfile"
};
const overrides = {
	"TitleTopWrapper": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"quarkly-title": "Header"
		}
	},
	"MainTitle": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--headline1",
			"text-align": "center",
			"children": "Business Profile"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"align-items": "center",
			"position": "sticky",
			"top": 0,
			"left": 0,
			"right": 0,
			"justify-content": "space-around",
			"sm-display": "grid",
			"sm-grid-template-columns": "repeat(2, 1fr)"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"href": "#organisation",
			"color": "#000000",
			"children": "Organisation's details",
			"opacity": "0.5"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"href": "#subscription",
			"color": "#000000",
			"children": "Subscription",
			"sm-text-align": "right",
			"opacity": "0.5"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"href": "#payment-methods",
			"color": "#000000",
			"children": "Payment methods",
			"opacity": "0.5"
		}
	},
	"link3": {
		"kind": "Link",
		"props": {
			"href": "#online-presence",
			"color": "#000000",
			"children": "Online presence",
			"sm-text-align": "right",
			"opacity": "0.5"
		}
	},
	"Organisations-details": {
		"kind": "Box",
		"props": {
			"padding": "0px 120px 0px 120px",
			"lg-flex-direction": "column",
			"lg-padding": "0px 0 0px 0",
			"align-self": "center",
			"quarkly-title": "Organisation's Details",
			"id": "organisation"
		}
	},
	"Org-D-Content": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "grid",
			"justify-content": "space-between",
			"margin": "0px 0px 40px 0px",
			"md-flex-direction": "column",
			"sm-grid-template-columns": "1fr",
			"md-grid-template-columns": "1fr 2fr",
			"grid-template-columns": "1fr"
		}
	},
	"Org-D-Title": {
		"kind": "Text",
		"props": {
			"color": "--darkL1",
			"lg-margin": "0px 0px 20px 0px",
			"margin": "0px 0 25px 0",
			"md-width": "100%",
			"md-margin": "0 0 20px 0",
			"sm-text-align": "center",
			"sm-margin": "0 0 30px 0",
			"font": "--headline2",
			"children": "Organisation's details"
		}
	},
	"Org-D-fields": {
		"kind": "Box",
		"props": {
			"md-margin": "0 0 25px 0",
			"md-width": "100%",
			"display": "grid",
			"grid-template-columns": "repeat(2, 1fr)",
			"grid-gap": "16px 24px",
			"margin": "0px 5px 0px 5px",
			"justify-items": "stretch",
			"sm-grid-template-columns": "1fr",
			"sm-grid-gap": "10px"
		}
	},
	"registered-name": {
		"kind": "Text",
		"props": {
			"required": true,
			"margin": "0px 0px 7px 0px",
			"color": "--darkL1",
			"children": "Registered Name",
			"font": "--base",
			"sm-margin": "0 0 0 0"
		}
	},
	"registered-name-input": {
		"kind": "registered-name-input",
		"props": {
			"display": "block",
			"placeholder-color": "LightGray",
			"background": "white",
			"placeholder": "Wavedropper LTD",
			"type": "text"
		}
	},
	"billing-address": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 7px 0px",
			"color": "--darkL1",
			"children": "Billing Address",
			"font": "--base",
			"sm-margin": "0 0 0 0"
		}
	},
	"billing-address-input": {
		"kind": "registered-name-input",
		"props": {
			"display": "block",
			"placeholder-color": "LightGray",
			"background": "white",
			"placeholder": "Unit 1, Buckingham Palace, London, SW1A 1AA, UK",
			"as": "textarea",
			"type": "text",
			"disabled": false,
			"required": true
		}
	},
	"VAT": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--darkL1",
			"children": "VAT",
			"font": "--base",
			"sm-margin": "0 0 0 0"
		}
	},
	"VAT-input": {
		"kind": "vat-input",
		"props": {
			"display": "block",
			"placeholder-color": "LightGray",
			"background": "white"
		}
	},
	"Subscription": {
		"kind": "Box",
		"props": {
			"padding": "0px 120px 0px 120px",
			"lg-flex-direction": "column",
			"lg-padding": "0px 0 0px 0",
			"align-self": "center",
			"quarkly-title": "Subscription",
			"id": "subscription"
		}
	},
	"Subscription-content": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "grid",
			"justify-content": "space-between",
			"margin": "0px 0px 40px 0px",
			"md-flex-direction": "column",
			"sm-grid-template-columns": "1fr",
			"grid-template-columns": "1fr"
		}
	},
	"Subscription-title": {
		"kind": "Text",
		"props": {
			"font": "--headline2",
			"color": "--darkL1",
			"lg-margin": "0px 0px 20px 0px",
			"margin": "0px 0 25px 0",
			"md-width": "100%",
			"md-margin": "0 0 20px 0",
			"sm-text-align": "center",
			"sm-margin": "0 0 30px 0",
			"children": "Subscription"
		}
	},
	"Subscription-fields": {
		"kind": "Box",
		"props": {
			"md-margin": "0 0 25px 0",
			"md-width": "100%",
			"display": "grid",
			"grid-template-columns": "repeat(2, 1fr)",
			"grid-gap": "16px 24px",
			"margin": "0px 5px 0px 5px",
			"justify-items": "stretch"
		}
	},
	"Current Plan": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--darkL1",
			"children": "Current Plan",
			"font": "--base"
		}
	},
	"Current Plan Value": {
		"kind": "plan-value-input",
		"props": {
			"display": "block",
			"placeholder-color": "LightGray",
			"required": false,
			"disabled": true,
			"placeholder": "N/A",
			"type": "text"
		}
	},
	"Addons": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--darkL1",
			"children": "Addons",
			"font": "--base"
		}
	},
	"Addons value": {
		"kind": "addons-value-input",
		"props": {
			"display": "block",
			"placeholder-color": "LightGray",
			"required": false,
			"disabled": true,
			"as": "textarea",
			"placeholder": "N/A",
			"type": "text"
		}
	},
	"Recurrence": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--darkL1",
			"children": "Recurrence",
			"font": "--base"
		}
	},
	"Recurrence value": {
		"kind": "recurrence-value-input",
		"props": {
			"display": "block",
			"placeholder-color": "LightGray",
			"required": false,
			"disabled": true,
			"placeholder": "N/A",
			"type": "text"
		}
	},
	"Recurring fee": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--darkL1",
			"children": "Recurring fee",
			"font": "--base"
		}
	},
	"Recurring fee value": {
		"kind": "recurring-fee-value-input",
		"props": {
			"display": "block",
			"placeholder-color": "LightGray",
			"required": false,
			"disabled": true,
			"placeholder": "N/A",
			"type": "text"
		}
	},
	"box8": {
		"kind": "Box",
		"props": {
			"padding": "0px 120px 0px 120px",
			"lg-flex-direction": "column",
			"lg-padding": "0px 0 0px 0",
			"align-self": "center",
			"quarkly-title": "Payment Methods",
			"id": "payment-methods"
		}
	},
	"box9": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "grid",
			"justify-content": "space-between",
			"margin": "0px 0px 40px 0px",
			"md-flex-direction": "column",
			"sm-grid-template-columns": "1fr",
			"grid-template-columns": "1fr"
		}
	},
	"text16": {
		"kind": "Text",
		"props": {
			"font": "--headline2",
			"color": "--darkL1",
			"lg-margin": "0px 0px 20px 0px",
			"margin": "0px 0 25px 0",
			"md-width": "100%",
			"md-margin": "0 0 20px 0",
			"sm-text-align": "center",
			"sm-margin": "0 0 30px 0",
			"children": "Payment methods"
		}
	},
	"box10": {
		"kind": "Box",
		"props": {
			"md-margin": "0 0 25px 0",
			"md-width": "100%",
			"display": "grid",
			"grid-gap": "16px 24px",
			"margin": "0px 5px 0px 5px",
			"justify-items": "start",
			"background": "rgba(0, 0, 0, 0) url(https://images.unsplash.com/photo-1604689598793-b8bf1dc445a1?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000) center/contain repeat scroll padding-box"
		}
	},
	"text17": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"justify-self": "center",
			"align-self": "center",
			"color": "--platinum",
			"background": "--color-primary",
			"padding": "5px 15px 5px 15px",
			"font": "--lead",
			"children": "Stripe Integration"
		}
	},
	"box11": {
		"kind": "Box",
		"props": {
			"padding": "0px 120px 0px 120px",
			"lg-flex-direction": "column",
			"lg-padding": "0px 0 0px 0",
			"align-self": "center",
			"quarkly-title": "Online Presence",
			"id": "online-presence"
		}
	},
	"box12": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "grid",
			"justify-content": "space-between",
			"margin": "0px 0px 40px 0px",
			"md-flex-direction": "column",
			"sm-grid-template-columns": "1fr",
			"grid-template-columns": "1fr"
		}
	},
	"text18": {
		"kind": "Text",
		"props": {
			"font": "--headline2",
			"color": "--darkL1",
			"lg-margin": "0px 0px 20px 0px",
			"margin": "0px 0 25px 0",
			"md-width": "100%",
			"md-margin": "0 0 20px 0",
			"sm-text-align": "center",
			"sm-margin": "0 0 30px 0",
			"children": "Online presence"
		}
	},
	"box13": {
		"kind": "Box",
		"props": {
			"md-margin": "0 0 25px 0",
			"md-width": "100%",
			"display": "grid",
			"grid-template-columns": "repeat(2, 1fr)",
			"grid-gap": "16px 24px",
			"margin": "0px 5px 0px 5px",
			"justify-items": "start"
		}
	},
	"text19": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 7px 0px",
			"color": "--darkL1",
			"children": "Website",
			"font": "--base"
		}
	},
	"input6": {
		"kind": "registered-name-input",
		"props": {
			"display": "block",
			"placeholder-color": "LightGray",
			"background": "white",
			"placeholder": "https://wavedropper.com"
		}
	},
	"text20": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 7px 0px",
			"color": "--darkL1",
			"children": "Facebook",
			"font": "--base"
		}
	},
	"input7": {
		"kind": "registered-name-input",
		"props": {
			"display": "block",
			"placeholder-color": "LightGray",
			"background": "white"
		}
	},
	"text21": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--darkL1",
			"children": "Twitter",
			"font": "--base"
		}
	},
	"input8": {
		"kind": "registered-name-input",
		"props": {
			"display": "block",
			"placeholder-color": "LightGray",
			"background": "white"
		}
	},
	"text22": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--darkL1",
			"children": "LinkedIn",
			"font": "--base"
		}
	},
	"input9": {
		"kind": "registered-name-input",
		"props": {
			"display": "block",
			"placeholder-color": "LightGray",
			"background": "white"
		}
	},
	"text23": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--darkL1",
			"children": "Instagram",
			"font": "--base"
		}
	},
	"input10": {
		"kind": "registered-name-input",
		"props": {
			"display": "block",
			"placeholder-color": "LightGray",
			"background": "white"
		}
	},
	"text24": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--darkL1",
			"children": "Pinterest",
			"font": "--base"
		}
	},
	"input11": {
		"kind": "registered-name-input",
		"props": {
			"display": "block",
			"placeholder-color": "LightGray",
			"background": "white"
		}
	},
	"box14": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "grid",
			"align-items": "end",
			"border-width": "1px 0 0 0",
			"border-style": "dotted",
			"justify-items": "center"
		}
	},
	"button1": {
		"kind": "Button",
		"props": {
			"font": "--lead",
			"background": "--color-secondary",
			"box-shadow": "--l"
		}
	},
	"SectionContent": {
		"props": {
			"color": "--eerieBlack"
		}
	},
	"text2": {
		"props": {}
	},
	"text3": {
		"props": {}
	},
	"text4": {
		"props": {}
	},
	"text5": {
		"props": {}
	},
	"text6": {
		"props": {}
	},
	"text7": {
		"props": {}
	},
	"text10": {
		"props": {}
	},
	"text11": {
		"props": {}
	},
	"text12": {
		"props": {}
	},
	"text9": {
		"props": {}
	},
	"text": {
		"props": {
			"children": "Business Profile"
		}
	},
	"box3": {
		"props": {}
	},
	"text1": {
		"props": {}
	},
	"text8": {
		"props": {}
	},
	"box2": {
		"props": {}
	},
	"box4": {
		"props": {}
	},
	"box6": {
		"props": {}
	},
	"button": {
		"props": {}
	},
	"text13": {
		"props": {
			"children": "One-Time Top up"
		}
	},
	"text14": {
		"props": {}
	},
	"text15": {
		"props": {}
	}
};

const BusinessProfile = ({ ...props
}) => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	const [user] = useUser();
	const [email, setEmail] = useState('');
	const {
		companiesData,
		companiesLoading,
		companiesError,
		fetchCompaniesData
	} = useGetCompaniesData();
	const {
		updateStatus: updateByIdStatus,
		updateLoading: updateByIdLoading,
		updateError: updateByIdError,
		updateDataById
	} = useUpdateContactDataById();
	useEffect(() => {
		if (user) {
			setEmail(user.email);
		}
	}, [user]);
	useEffect(() => {
		if (email) {
			handleFetchCompanies(email);
		}
	}, [email]);
	useEffect(() => {
		if (companiesData) {
			console.log("CP", companiesData.companiesData[0]);
		}
	}, [companiesData]);

	const handleFetchCompanies = email => {
		console.log("fetching companies...");
		const fields = ['id', 'name', 'address', 'contacts', 'site', 'additionalinformation', '32529', '32531', '32533', '36117', '36283', '36287', '36289'];
		console.log(fields);
		fetchCompaniesData(email, fields);
	};

	const handleUpdateById = async (contactId, fields) => {
		try {
			await updateDataById(contactId, fields);
		} catch (error) {
			console.error(error);
		}
	};

	return <>
		{companiesLoading && <p>
			<br />
			<br />
			<br />
			<br />
			Loading...
		</p>}
		{companiesError && <p>
			Error: 
			{companiesError}
		</p>}
		{companiesData && companiesData.companiesData.map(company => <BusinessProfileForm
			key={company.id}
			override={override}
			rest={rest}
			companyData={company}
			companiesLoading={companiesLoading}
			setBusinessData={handleUpdateById}
			updateLoading={updateByIdLoading}
		/>)}
	</>;
};

Object.assign(BusinessProfile, { ...Section,
	defaultProps,
	overrides
});
export default BusinessProfile;