import React, { useState, useEffect } from 'react';
import { Text, Link, Box, Input, Button, Section } from "@quarkly/widgets";
import { Override } from "@quarkly/components";
import atomize from "@quarkly/atomize";

const BusinessProfileForm = ({
	override,
	rest,
	companyData,
	setBusinessData,
	updateLoading,
	...props
}) => {
	const [updatedData, setUpdatedData] = useState(companyData);

	const handleChange = fieldName => e => {
		const {
			value
		} = e.target;
		setUpdatedData(prevData => ({ ...prevData,
			[fieldName]: value
		}));
	};

	const handlePhoneChange = index => e => {
		const {
			value
		} = e.target;
		setUpdatedData(prevData => ({ ...prevData,
			phones: prevData.phones.map((phone, i) => i === index ? { ...phone,
				maskedNumber: value,
				number: value
			} : phone)
		}));
	};

	const handleCustomFieldChange = fieldId => e => {
		const {
			value
		} = e.target;
		setUpdatedData(prevData => ({ ...prevData,
			customFieldData: prevData.customFieldData.map(cf => cf.field.id === fieldId ? { ...cf,
				value: value,
				stringValue: value
			} : cf)
		}));
	};

	const getCustomFieldValue = (data, fieldName) => {
		const customField = data.customFieldData.find(cf => cf.field.name === fieldName);
		console.log('cf', customField.value);
		return customField ? customField.value : null;
	};

	const handleSave = () => {
		setBusinessData(companyData.id, updatedData);
	};

	useEffect(() => {
		console.log("UD", updatedData);
	}, [updatedData]);
	return <Section {...rest}>
		      
		<form>
			        
			<Override slot="SectionContent" display="grid" justify-items="stretch" />
			        
			<Box {...override("TitleTopWrapper")}>
				          
				<Text {...override("MainTitle")} />
				          
				<Box {...override("box1")}>
					            
					<Link {...override("link")} />
					            
					<Link {...override("link1")} />
					            
					<Link {...override("link2")} />
					            
					<Link {...override("link3")} />
					          
				</Box>
				        
			</Box>
			        
			<Box {...override("Organisations-details")}>
				          
				<Box {...override("Org-D-Content")}>
					            
					<Text {...override("Org-D-Title")} />
					            
					<Box {...override("Org-D-fields")}>
						              
						<Text {...override("registered-name")} />
						              
						<Input value={updatedData.name || ""} onChange={handleChange("name")} {...override("registered-name-input")} />
						              
						<Text {...override("billing-address")} />
						              
						<Input value={updatedData.address || ""} onChange={handleChange("address")} {...override("billing-address-input")} />
						              
						<Text {...override("VAT")} />
						              
						<Input {...override("VAT-input")} value={getCustomFieldValue(updatedData, "VAT") || ""} onChange={handleCustomFieldChange(36283)} />
						            
					</Box>
					          
				</Box>
				        
			</Box>
			        
			<Box {...override("Subscription")}>
				          
				<Box {...override("Subscription-content")}>
					            
					<Text {...override("Subscription-title")} />
					            
					<Box {...override("Subscription-fields")}>
						              
						<Text {...override("Current Plan")} />
						              
						<Input {...override("Current Plan Value")} value={getCustomFieldValue(updatedData, "Subscription Plan") || ""} onChange={handleCustomFieldChange(32529)} />
						              
						<Text {...override("Addons")} />
						              
						<Input {...override("Addons value")} value={getCustomFieldValue(updatedData, "Add-ons") || ""} onChange={handleCustomFieldChange(32531)} />
						              
						<Text {...override("Recurrence")} />
						              
						<Input {...override("Recurrence value")} value={getCustomFieldValue(updatedData, "Recurrence") || ""} onChange={handleCustomFieldChange(36117)} />
						              
						<Text {...override("Recurring fee")} />
						              
						<Input {...override("Recurring fee value")} value={getCustomFieldValue(updatedData, "Recurring fee (GBP)") || ""} onChange={handleCustomFieldChange(32533)} />
						            
					</Box>
					          
				</Box>
				        
			</Box>
			        
			<Box {...override("box8")}>
				          
				<Box {...override("box9")}>
					            
					<Text {...override("text16")} />
					            
					<Box {...override("box10")}>
						              
						<Text {...override("text17")} />
						            
					</Box>
					          
				</Box>
				        
			</Box>
			        
			<Box {...override("box11")}>
				          
				<Box {...override("box12")}>
					            
					<Text {...override("text18")} />
					            
					<Box {...override("box13")}>
						              
						<Text {...override("text19")} />
						              
						<Input {...override("input6")} />
						              
						<Text {...override("text20")} />
						              
						<Input {...override("input7")} />
						              
						<Text {...override("text21")} />
						              
						<Input {...override("input8")} />
						              
						<Text {...override("text22")} />
						              
						<Input {...override("input9")} />
						              
						<Text {...override("text23")} />
						              
						<Input {...override("input10")} />
						              
						<Text {...override("text24")} />
						              
						<Input {...override("input11")} />
						            
					</Box>
					          
				</Box>
				          
				<Box {...override("box14")}>
					            
					<Button disabled={updateLoading} onClick={handleSave} {...override("button1")}>
						              Save Changes
            
					</Button>
					            
					<Text>
						{updateLoading && <p>
							Updating contact data by ID...
						</p>}
					</Text>
					          
				</Box>
				        
			</Box>
			      
		</form>
		    
	</Section>;
};

export default atomize(BusinessProfileForm)({
	name: "BusinessProfileForm",
	effects: {
		hover: ":hover"
	},
	normalize: true,
	mixins: true,
	description: {
		// paste here description for your component
		en: "BusinessProfileForm — my awesome component"
	},
	propInfo: {}
});