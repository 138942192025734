import React, { useState, useEffect } from 'react';
import { Box, Input, Button, Text, Icon, Link } from '@quarkly/widgets';
import { useOverrides, Override } from '@quarkly/components';
import { getAuth, sendEmailVerification, signInWithEmailAndPassword, sendPasswordResetEmail, signOut } from "firebase/auth";
import SignUpForm from './SignUpForm';
import { useMessage } from './MessageProvider';
import { FaRegEyeSlash, FaRegEnvelope } from "react-icons/fa";
const overrides = {
	"Password Wrapper": {
		"props": {
			"display": "flex"
		}
	},
	"Show-Hide pw icon": {
		"props": {
			"category": "fa",
			"icon": FaRegEyeSlash
		}
	},
	"Email Login Title": {
		"props": {
			"text-align": "center",
			"children": "Sign in with Email"
		}
	},
	"Password Label": {
		"props": {
			"display": "none"
		}
	},
	"Email Label": {
		"props": {
			"display": "none"
		}
	},
	"Password input": {
		"props": {
			"width": "80%",
			"name": "password",
			"id": "pwinputsin"
		}
	},
	"Email input": {
		"props": {
			"width": "100%",
			"name": "email",
			"id": "emailinputsin",
			"autocomplete": "email"
		}
	},
	"Hide pw icon": {
		"props": {}
	},
	"Show": {
		"props": {},
		"overrides": {
			"Hide Password": {
				"props": {
					"type": "button",
					"disabled": false
				}
			}
		}
	},
	"Email icon": {
		"props": {
			"category": "fa",
			"icon": FaRegEnvelope
		}
	},
	'EmailInput': {
		'kind': 'Input',
		'props': {
			'type': 'email',
			'placeholder': 'Email Address',
			'margin-bottom': '16px',
			'width': '100%',
			'padding': '8px',
			'border': '1px solid #ddd',
			'border-radius': '4px',
			'required': true,
			'id': 'email',
			'name': 'email',
			"autocomplete": "email"
		}
	},
	'PasswordInput': {
		'kind': 'Input',
		'props': {
			'type': 'password',
			'placeholder': 'Password',
			'margin-bottom': '16px',
			'width': '100%',
			'padding': '8px',
			'border': '1px solid #ddd',
			'border-radius': '4px',
			'required': true,
			'id': 'password',
			'name': 'password'
		}
	},
	'ConfirmPasswordInput': {
		'kind': 'Input',
		'props': {
			'type': 'password',
			'placeholder': 'Confirm Password',
			'margin-bottom': '24px',
			'width': '100%',
			'padding': '8px',
			'border': '1px solid #ddd',
			'border-radius': '4px',
			'required': true,
			'id': 'confirm-password',
			'name': 'confirm-password',
			'autocomplete': "off"
		}
	},
	'SubmitButton': {
		'kind': 'Button',
		'props': {
			'children': 'Sign Up',
			'width': '100%',
			'padding': '10px',
			'background': '--color-primary',
			'color': '#fff',
			'border': 'none',
			'border-radius': '4px',
			'cursor': 'pointer',
			':hover': {
				'background': '--color-primary-dark'
			},
			'type': 'submit'
		}
	},
	'ErrorText': {
		'kind': 'Text',
		'props': {
			'color': 'red',
			'margin-bottom': '16px',
			'font-size': '14px'
		}
	}
};
const defaultProps = {};

const EmailLogin = ({
	setIsEmailVerfied,
	...props
}) => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');
	const auth = getAuth();
	const [showPassword, setShowPassword] = useState(false);
	const [showSignup, setShowSignup] = useState(false);
	const [showResendVerfication, setShowResendVerification] = useState(false);
	const [veriResent, setVeriResent] = useState(false);
	const {
		addMessage
	} = useMessage();

	const toggleShowPassword = () => {
		setShowPassword(prevShowPassword => !prevShowPassword);
	};

	const toggleShowSignup = () => {
		setShowSignup(prevShowSignup => !prevShowSignup);
	};

	const verifyUserEmail = () => {
		auth.currentUser.reload();

		if (!auth.currentUser.emailVerified) {
			return false;
		}

		setIsEmailVerfied(true);
		return true;
	};

	const handleVerification = async e => {
		e.preventDefault();

		try {
			await sendEmailVerification(auth.currentUser);
			console.log("verification sent");
			addMessage('Verification sent.', '#2A9D8F');
			setVeriResent(true);
		} catch (error) {
			setError(error.message);
		}
	};

	const handlePasswordReset = async e => {
		e.preventDefault();
		const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

		if (!emailRegex.test(email)) {
			addMessage('Enter your email address above!');
			return;
		}

		;

		try {
			await sendPasswordResetEmail(auth, email);
			console.log("pw reset sent");
			addMessage("If your email is valid, you'll receive password reset instructions.");
		} catch (error) {
			console.log("account does not exist");
			addMessage("If your email is valid, you'll receive password reset instructions.");
		}
	};

	const handleSignIn = async e => {
		e.preventDefault();

		try {
			await signInWithEmailAndPassword(auth, email, password);
			const isEmailVerified = verifyUserEmail();
			console.log('Verifying users email...');
			console.log('is email valid? ', isEmailVerified);

			if (!isEmailVerified) {
				setError('Please verify your email address before signing in.');
				setShowResendVerification(true);
				return;
			}

			setShowResendVerification(false);
			setError('');
			console.log('Users email verified.');
		} catch (error) {
			setError(error.message);
		}
	};

	const handleSignOut = async () => {
		try {
			await signOut(auth); //alert('User signed out successfully');
		} catch (error) {
			setError(error.message);
		}
	}; // handle notifications


	useEffect(() => {
		addMessage(error.replace("Firebase: ", ""), '#DA3E52');
	}, [error]);
	return <Box {...rest}>
		{!showSignup && <>
			<form>
				<Text {...override('Email Login Title')} as="h2" />
				<Box {...override('Email Wrapper')}>
					<Text for="email" {...override('Email Label')} as="label" />
					<Input
						{...override('Email input')}
						type="email"
						value={email}
						onChange={e => setEmail(e.target.value)}
						required
						placeholder="Email address"
					/>
				</Box>
				<Box {...override('Password Wrapper')}>
					<Text for="password" {...override('Password Label')} as="label" />
					<Input
						{...override('Password input')}
						type={showPassword ? "text" : "password"}
						value={password}
						onChange={e => setPassword(e.target.value)}
						required
						placeholder="Password"
					/>
					<Button {...override('Show-Hide Password')} type="button" onClick={toggleShowPassword}>
						<Icon {...override('Show-Hide pw icon')} />
					</Button>
				</Box>
				<Box {...override('Email Btn Wrapper')}>
					<Button {...override('Email Signin Btn')} onClick={handleSignIn} />
					<Icon {...override('Email icon')} />
				</Box>
				<Box {...override('Sign Up link Wrapper')}>
					<Text {...override('Sign Up link')} onClick={toggleShowSignup}>
						Sign Up
					</Text>
					<Text {...override('Reset PW link')} onClick={handlePasswordReset}>
						Reset password
					</Text>
				</Box>
			</form>
			{!veriResent && showResendVerfication && <Link {...override('ResendEmailVerification')} onClick={handleVerification}>
				Resend verification email.
			</Link>}
		</>}
		{showSignup && <>
			<Text {...override('Email Signup Title')} as="h2" />
			<SignUpForm override={override} />
			<Text {...override('Sign Up link')} onClick={toggleShowSignup}>
				Back to Sign In
			</Text>
		</>}
	</Box>;
};

Object.assign(EmailLogin, { ...Box,
	defaultProps,
	overrides
});
export default EmailLogin;