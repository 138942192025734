// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'; // TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
	apiKey: "AIzaSyAEPp4gtD9Wvsa0EmrwtyRGRX9nmI7uHeo",
	authDomain: "auth.wavedropper.com",
	//"wavedropper-ltd-client-portal.firebaseapp.com"
	projectId: "wavedropper-ltd-client-portal",
	storageBucket: "wavedropper-ltd-client-portal.appspot.com",
	messagingSenderId: "935130995406",
	appId: "1:935130995406:web:88291adae7a38267ba0e9f",
	measurementId: "G-72JWEFB3LE"
};
let app = null; // Export the getFirebase function, which will return an instance of Firebase

export function getFirebase() {
	// Firebase does not work with server-side,
	// so we check that we are on the client-side (in the browser).
	if (typeof window !== 'undefined') {
		// If Firebase has already been initialized, then return the existing instance.
		if (app) return app;
		app = initializeApp(firebaseConfig);
		return app;
	}

	return null;
}
export default {};