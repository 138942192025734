import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Button, Strong, Icon, Section } from "@quarkly/widgets";
import { FaTasks } from "react-icons/fa";
import { GiCheckeredFlag } from "react-icons/gi";
import { MdUpdate } from "react-icons/md";
const defaultProps = {
	"padding": "120px 0 120px 0",
	"lg-margin": "0 0 0 0",
	"lg-padding": "80px 0 80px 0",
	"quarkly-title": "CustomerService",
	"background": "#ffffff"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"quarkly-title": "Header",
			"display": "grid",
			"justify-items": "center"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--headline1",
			"text-align": "center",
			"children": "Customer Service"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"display": "grid",
			"position": "sticky",
			"top": 0,
			"left": 0,
			"right": 0,
			"sm-display": "grid",
			"margin": "50px 0px 30px 0px",
			"grid-gap": "30px",
			"max-width": "800px"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "100px"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"children": "Welcome to WaveDropper's Support Center! Need assistance? Quickly raise a ticket by emailing us at support@wavedropper.com or using the \"Raise a Ticket\" button below. Track the progress of your inquiries here and reply directly from your email for any follow-ups. We're dedicated to ensuring a seamless support experience."
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"display": "grid",
			"justify-items": "center",
			"align-items": "center"
		}
	},
	"button": {
		"kind": "Button",
		"props": {
			"border-radius": "15px"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "grid",
			"grid-gap": "16px"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"lg-flex-direction": "column",
			"border-width": "5px",
			"border-color": "--color-secondary",
			"border-style": "solid",
			"border-radius": "15px",
			"display": "grid",
			"grid-template-columns": "1fr 2fr",
			"box-shadow": "--xl"
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"md-flex-direction": "column",
			"sm-grid-template-columns": "1fr",
			"grid-template-columns": "1fr",
			"id": "project1",
			"flex-direction": "column",
			"padding": "15px 15px 15px 15px",
			"background": "--color-secondary",
			"border-radius": "8px 0 0 8px",
			"color": "--light"
		}
	},
	"box7": {
		"kind": "Box",
		"props": {
			"sm-grid-template-columns": "1fr",
			"grid-template-columns": "repeat(2, 1fr)",
			"id": "project1",
			"display": "flex",
			"justify-content": "space-between",
			"md-flex-direction": "column"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"lg-margin": "0px 0px 20px 0px",
			"margin": "0px 0 25px 0",
			"md-width": "100%",
			"md-margin": "0 0 20px 0",
			"sm-text-align": "center",
			"sm-margin": "0 0 30px 0",
			"quarkly-title": "Ticket number",
			"children": <Strong>
				Ticket number:
			</Strong>
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"lg-margin": "0px 0px 20px 0px",
			"margin": "0px 0 25px 20px",
			"md-margin": "0 0 20px 0",
			"sm-text-align": "center",
			"sm-margin": "0 0 30px 0",
			"quarkly-title": "Value",
			"children": "#641681681ASEF"
		}
	},
	"box8": {
		"kind": "Box",
		"props": {
			"sm-grid-template-columns": "1fr",
			"grid-template-columns": "repeat(2, 1fr)",
			"id": "project1",
			"display": "flex",
			"justify-content": "space-between",
			"md-flex-direction": "column"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"lg-margin": "0px 0px 20px 0px",
			"margin": "0px 0 25px 0",
			"md-width": "100%",
			"md-margin": "0 0 20px 0",
			"sm-text-align": "center",
			"sm-margin": "0 0 30px 0",
			"quarkly-title": "Last Update",
			"children": <Strong
				overflow-wrap="normal"
				word-break="normal"
				white-space="normal"
				text-indent="0"
				text-overflow="clip"
				hyphens="manual"
				user-select="auto"
				pointer-events="auto"
			>
				Last Update:
			</Strong>
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"lg-margin": "0px 0px 20px 0px",
			"margin": "0px 0 25px 20px",
			"md-margin": "0 0 20px 0",
			"sm-text-align": "center",
			"sm-margin": "0 0 30px 0",
			"quarkly-title": "Value",
			"children": "10 minutes ago"
		}
	},
	"box9": {
		"kind": "Box",
		"props": {
			"sm-grid-template-columns": "1fr",
			"grid-template-columns": "repeat(2, 1fr)",
			"id": "project1",
			"display": "flex",
			"justify-content": "space-between",
			"md-flex-direction": "column"
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			"lg-margin": "0px 0px 20px 0px",
			"margin": "0px 0 25px 0",
			"md-width": "100%",
			"md-margin": "0 0 20px 0",
			"sm-text-align": "center",
			"sm-margin": "0 0 30px 0",
			"quarkly-title": "Tags",
			"children": <Strong
				overflow-wrap="normal"
				word-break="normal"
				white-space="normal"
				text-indent="0"
				text-overflow="clip"
				hyphens="manual"
				user-select="auto"
				pointer-events="auto"
			>
				Tags:
			</Strong>
		}
	},
	"text7": {
		"kind": "Text",
		"props": {
			"lg-margin": "0px 0px 20px 0px",
			"margin": "0px 0 25px 20px",
			"md-margin": "0 0 20px 0",
			"sm-text-align": "center",
			"sm-margin": "0 0 30px 0",
			"quarkly-title": "Value",
			"children": "Urgent"
		}
	},
	"box10": {
		"kind": "Box",
		"props": {
			"md-flex-direction": "column",
			"sm-grid-template-columns": "1fr",
			"grid-template-columns": "1fr",
			"id": "project1",
			"flex-direction": "column",
			"padding": "15px 15px 15px 15px",
			"display": "block"
		}
	},
	"box11": {
		"kind": "Box",
		"props": {
			"md-flex-direction": "column",
			"grid-template-columns": "repeat(3, 1fr)",
			"id": "project1",
			"display": "grid",
			"font": "normal 600 16px/1.5 --fontFamily-googlePoppins",
			"padding": "5px 15px 2.5px 15px",
			"margin": "0px 0px 5px 0px",
			"border-width": "0 0 1px 0",
			"border-style": "dotted",
			"md-height": "30px",
			"justify-items": "center",
			"color": "--secondary"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"category": "fa",
			"icon": FaTasks,
			"title": "Task",
			"size": "64px",
			"font": "600 20px/96px Poppins, sans-serif"
		}
	},
	"icon1": {
		"kind": "Icon",
		"props": {
			"category": "gi",
			"icon": GiCheckeredFlag,
			"title": "Status",
			"size": "64px",
			"font": "600 20px/96px Poppins, sans-serif"
		}
	},
	"icon2": {
		"kind": "Icon",
		"props": {
			"category": "md",
			"title": "Last update",
			"icon": MdUpdate,
			"size": "64px",
			"font": "600 20px/96px Poppins, sans-serif"
		}
	},
	"box12": {
		"kind": "Box",
		"props": {
			"md-flex-direction": "column",
			"grid-template-columns": "repeat(3, 1fr)",
			"id": "project1",
			"display": "grid",
			"font": "--base",
			"padding": "5px 15px 5px 15px",
			"background": "--color-light",
			"border-radius": "15px",
			"color": "--eerieBlack",
			"margin": "0px 0px 5px 0px",
			"sm-grid-template-columns": "1fr",
			"sm-grid-gap": "10px",
			"sm-font": "normal 400 15px/1.1 --fontFamily-googlePoppins",
			"sm-padding": "5px 5px 5px 5px"
		}
	},
	"text8": {
		"kind": "Text",
		"props": {
			"md-width": "100%",
			"quarkly-title": "Value",
			"margin": "0 0px 0 0px",
			"md-display": "grid",
			"md-align-items": "center",
			"sm-text-align": "left",
			"children": "Content update"
		}
	},
	"text9": {
		"kind": "Text",
		"props": {
			"md-width": "100%",
			"quarkly-title": "Value",
			"margin": "0 0px 0 0px",
			"text-align": "center",
			"md-display": "grid",
			"md-align-items": "center",
			"sm-text-align": "left",
			"children": "Scheduled"
		}
	},
	"text10": {
		"kind": "Text",
		"props": {
			"md-width": "100%",
			"quarkly-title": "Value",
			"margin": "0 0px 0 0px",
			"text-align": "right",
			"md-display": "grid",
			"md-align-items": "center",
			"sm-text-align": "left",
			"children": "10:37 07/04/2024"
		}
	},
	"box13": {
		"kind": "Box",
		"props": {
			"lg-flex-direction": "column",
			"border-width": "5px",
			"border-color": "--color-eerieBlack",
			"border-style": "solid",
			"border-radius": "15px",
			"display": "grid",
			"grid-template-columns": "1fr 2fr",
			"box-shadow": "--xl",
			"opacity": "0.6"
		}
	},
	"box14": {
		"kind": "Box",
		"props": {
			"md-flex-direction": "column",
			"sm-grid-template-columns": "1fr",
			"grid-template-columns": "1fr",
			"id": "project1",
			"flex-direction": "column",
			"padding": "15px 15px 15px 15px",
			"background": "--color-platinum",
			"border-radius": "8px 0 0 8px",
			"color": "--eerieBlack"
		}
	},
	"box15": {
		"kind": "Box",
		"props": {
			"sm-grid-template-columns": "1fr",
			"grid-template-columns": "repeat(2, 1fr)",
			"id": "project1",
			"display": "flex",
			"justify-content": "space-between",
			"md-flex-direction": "column"
		}
	},
	"text11": {
		"kind": "Text",
		"props": {
			"lg-margin": "0px 0px 20px 0px",
			"margin": "0px 0 25px 0",
			"md-width": "100%",
			"md-margin": "0 0 20px 0",
			"sm-text-align": "center",
			"sm-margin": "0 0 30px 0",
			"quarkly-title": "Ticket number",
			"children": <Strong>
				Ticket number:
			</Strong>
		}
	},
	"text12": {
		"kind": "Text",
		"props": {
			"lg-margin": "0px 0px 20px 0px",
			"margin": "0px 0 25px 20px",
			"md-margin": "0 0 20px 0",
			"sm-text-align": "center",
			"sm-margin": "0 0 30px 0",
			"quarkly-title": "Value",
			"children": "#641681681ASEF"
		}
	},
	"box16": {
		"kind": "Box",
		"props": {
			"sm-grid-template-columns": "1fr",
			"grid-template-columns": "repeat(2, 1fr)",
			"id": "project1",
			"display": "flex",
			"justify-content": "space-between",
			"md-flex-direction": "column"
		}
	},
	"text13": {
		"kind": "Text",
		"props": {
			"lg-margin": "0px 0px 20px 0px",
			"margin": "0px 0 25px 0",
			"md-width": "100%",
			"md-margin": "0 0 20px 0",
			"sm-text-align": "center",
			"sm-margin": "0 0 30px 0",
			"quarkly-title": "Last Update",
			"children": <Strong
				overflow-wrap="normal"
				word-break="normal"
				white-space="normal"
				text-indent="0"
				text-overflow="clip"
				hyphens="manual"
				user-select="auto"
				pointer-events="auto"
			>
				Last Update:
			</Strong>
		}
	},
	"text14": {
		"kind": "Text",
		"props": {
			"lg-margin": "0px 0px 20px 0px",
			"margin": "0px 0 25px 20px",
			"md-margin": "0 0 20px 0",
			"sm-text-align": "center",
			"sm-margin": "0 0 30px 0",
			"quarkly-title": "Value",
			"children": "15:05 05/04/2024"
		}
	},
	"box17": {
		"kind": "Box",
		"props": {
			"sm-grid-template-columns": "1fr",
			"grid-template-columns": "repeat(2, 1fr)",
			"id": "project1",
			"display": "flex",
			"justify-content": "space-between",
			"md-flex-direction": "column"
		}
	},
	"text15": {
		"kind": "Text",
		"props": {
			"lg-margin": "0px 0px 20px 0px",
			"margin": "0px 0 25px 0",
			"md-width": "100%",
			"md-margin": "0 0 20px 0",
			"sm-text-align": "center",
			"sm-margin": "0 0 30px 0",
			"quarkly-title": "Tags",
			"children": <Strong
				overflow-wrap="normal"
				word-break="normal"
				white-space="normal"
				text-indent="0"
				text-overflow="clip"
				hyphens="manual"
				user-select="auto"
				pointer-events="auto"
			>
				Tags:
			</Strong>
		}
	},
	"text16": {
		"kind": "Text",
		"props": {
			"margin": "0px 0 25px 20px",
			"md-margin": "0 0 20px 0",
			"sm-text-align": "center",
			"sm-margin": "0 0 30px 0",
			"quarkly-title": "Value",
			"lg-margin": "0px 0px 20px 0px",
			"children": "Urgent"
		}
	},
	"box18": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"position": "absolute",
			"align-self": "center",
			"justify-self": "center",
			"display": "grid",
			"justify-items": "center",
			"align-items": "center"
		}
	},
	"text17": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "normal 700 52px/1.2 --fontFamily-sansTrebuchet",
			"children": "CLOSED"
		}
	},
	"box19": {
		"kind": "Box",
		"props": {
			"md-flex-direction": "column",
			"sm-grid-template-columns": "1fr",
			"grid-template-columns": "1fr",
			"id": "project1",
			"flex-direction": "column",
			"padding": "15px 15px 15px 15px",
			"display": "block"
		}
	},
	"box20": {
		"kind": "Box",
		"props": {
			"md-flex-direction": "column",
			"grid-template-columns": "repeat(3, 1fr)",
			"id": "project1",
			"display": "grid",
			"font": "normal 600 16px/1.5 --fontFamily-googlePoppins",
			"padding": "5px 15px 2.5px 15px",
			"color": "--eerieBlack",
			"margin": "0px 0px 5px 0px",
			"border-width": "0 0 1px 0",
			"border-style": "dotted",
			"md-height": "30px",
			"justify-items": "center"
		}
	},
	"icon3": {
		"kind": "Icon",
		"props": {
			"category": "fa",
			"title": "Task",
			"icon": FaTasks,
			"size": "64px",
			"font": "600 20px/96px Poppins, sans-serif"
		}
	},
	"icon4": {
		"kind": "Icon",
		"props": {
			"category": "gi",
			"title": "Status",
			"icon": GiCheckeredFlag,
			"size": "64px",
			"font": "600 20px/96px Poppins, sans-serif"
		}
	},
	"icon5": {
		"kind": "Icon",
		"props": {
			"category": "md",
			"title": "Last update",
			"icon": MdUpdate,
			"size": "64px",
			"font": "600 20px/96px Poppins, sans-serif"
		}
	},
	"box21": {
		"kind": "Box",
		"props": {
			"md-flex-direction": "column",
			"grid-template-columns": "repeat(3, 1fr)",
			"id": "project1",
			"display": "grid",
			"font": "--base",
			"padding": "5px 15px 5px 15px",
			"background": "--color-light",
			"border-radius": "15px",
			"color": "--eerieBlack",
			"margin": "0px 0px 5px 0px",
			"sm-grid-template-columns": "1fr",
			"sm-grid-gap": "10px",
			"sm-font": "normal 400 15px/1.1 --fontFamily-googlePoppins",
			"sm-padding": "5px 5px 5px 5px"
		}
	},
	"text18": {
		"kind": "Text",
		"props": {
			"md-width": "100%",
			"quarkly-title": "Value",
			"margin": "0 0px 0 0px",
			"md-display": "grid",
			"md-align-items": "center",
			"sm-text-align": "left",
			"children": "Content update"
		}
	},
	"text19": {
		"kind": "Text",
		"props": {
			"md-width": "100%",
			"quarkly-title": "Value",
			"margin": "0 0px 0 0px",
			"text-align": "center",
			"md-display": "grid",
			"md-align-items": "center",
			"sm-text-align": "left",
			"children": "Completed"
		}
	},
	"text20": {
		"kind": "Text",
		"props": {
			"md-width": "100%",
			"quarkly-title": "Value",
			"margin": "0 0px 0 0px",
			"text-align": "right",
			"md-display": "grid",
			"md-align-items": "center",
			"sm-text-align": "left",
			"children": "15:05 05/04/2024"
		}
	},
	"box22": {
		"kind": "Box",
		"props": {
			"md-flex-direction": "column",
			"grid-template-columns": "repeat(3, 1fr)",
			"id": "project1",
			"display": "grid",
			"font": "--base",
			"padding": "5px 15px 5px 15px",
			"background": "--color-light",
			"border-radius": "15px",
			"color": "--eerieBlack",
			"margin": "0px 0px 5px 0px",
			"sm-grid-template-columns": "1fr",
			"sm-grid-gap": "10px",
			"sm-font": "normal 400 15px/1.1 --fontFamily-googlePoppins",
			"sm-padding": "5px 5px 5px 5px"
		}
	},
	"text21": {
		"kind": "Text",
		"props": {
			"md-width": "100%",
			"quarkly-title": "Value",
			"margin": "0 0px 0 0px",
			"md-display": "grid",
			"md-align-items": "center",
			"sm-text-align": "left",
			"children": "Landing page development"
		}
	},
	"text22": {
		"kind": "Text",
		"props": {
			"md-width": "100%",
			"quarkly-title": "Value",
			"margin": "0 0px 0 0px",
			"text-align": "center",
			"md-display": "grid",
			"md-align-items": "center",
			"sm-text-align": "left",
			"children": "Completed"
		}
	},
	"text23": {
		"kind": "Text",
		"props": {
			"md-width": "100%",
			"quarkly-title": "Value",
			"margin": "0 0px 0 0px",
			"text-align": "right",
			"md-display": "grid",
			"md-align-items": "center",
			"sm-text-align": "left",
			"children": "15:05 05/04/2024"
		}
	}
};

const CustomerService = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" display="grid" justify-items="stretch" sm-width="95%" />
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Box {...override("box1")}>
				<Box {...override("box2")}>
					<Text {...override("text1")} />
				</Box>
				<Box {...override("box3")}>
					<Button {...override("button")}>
						Raise a Ticket
					</Button>
				</Box>
			</Box>
		</Box>
		<Box {...override("box4")}>
			<Box {...override("box5")}>
				<Box {...override("box6")}>
					<Box {...override("box7")}>
						<Text {...override("text2")} />
						<Text {...override("text3")} />
					</Box>
					<Box {...override("box8")}>
						<Text {...override("text4")} />
						<Text {...override("text5")} />
					</Box>
					<Box {...override("box9")}>
						<Text {...override("text6")} />
						<Text {...override("text7")} />
					</Box>
				</Box>
				<Box {...override("box10")}>
					<Box {...override("box11")}>
						<Icon {...override("icon")} />
						<Icon {...override("icon1")} />
						<Icon {...override("icon2")} />
					</Box>
					<Box {...override("box12")}>
						<Text {...override("text8")} />
						<Text {...override("text9")} />
						<Text {...override("text10")} />
					</Box>
				</Box>
			</Box>
			<Box {...override("box13")}>
				<Box {...override("box14")}>
					<Box {...override("box15")}>
						<Text {...override("text11")} />
						<Text {...override("text12")} />
					</Box>
					<Box {...override("box16")}>
						<Text {...override("text13")} />
						<Text {...override("text14")} />
					</Box>
					<Box {...override("box17")}>
						<Text {...override("text15")} />
						<Text {...override("text16")} />
					</Box>
				</Box>
				<Box {...override("box18")}>
					<Text {...override("text17")} />
				</Box>
				<Box {...override("box19")}>
					<Box {...override("box20")}>
						<Icon {...override("icon3")} />
						<Icon {...override("icon4")} />
						<Icon {...override("icon5")} />
					</Box>
					<Box {...override("box21")}>
						<Text {...override("text18")} />
						<Text {...override("text19")} />
						<Text {...override("text20")} />
					</Box>
					<Box {...override("box22")}>
						<Text {...override("text21")} />
						<Text {...override("text22")} />
						<Text {...override("text23")} />
					</Box>
				</Box>
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(CustomerService, { ...Section,
	defaultProps,
	overrides
});
export default CustomerService;