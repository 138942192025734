import { useState, useCallback } from 'react'; // Function to fetch user data

const fetchUserData = async (email, fields = "id,name") => {
	const response = await fetch('https://portal.wavedropper.com/.netlify/functions/getUserDataByEmail', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			email,
			fields
		})
	});

	if (response.ok) {
		const data = await response.json();
		return data;
	} else {
		const errorText = await response.text();
		throw new Error(`Failed to fetch user data: ${errorText}`);
	}
}; // Function to update user data


const updateUserData = async (email, fields) => {
	const response = await fetch('https://portal.wavedropper.com/.netlify/functions/updateUserDataByEmail', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			email,
			fields
		})
	});

	if (response.ok) {
		const data = await response.json();
		return data;
	} else {
		const errorText = await response.text();
		throw new Error(`Failed to update user data: ${errorText}`);
	}
}; // Function to get companies data


const getCompaniesData = async (email, fields) => {
	const response = await fetch('https://portal.wavedropper.com/.netlify/functions/getCompaniesData', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			email,
			fields
		})
	});

	if (response.ok) {
		const data = await response.json();
		console.log("companies data =", data);
		return data;
	} else {
		const errorText = await response.text();
		throw new Error(`Failed to fetch companies data: ${errorText}`);
	}
}; // Function to update contact data by ID


const updateContactDataById = async (contactId, fields) => {
	const response = await fetch('https://portal.wavedropper.com/.netlify/functions/updateContactById', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			contactId,
			fields
		})
	});

	if (response.ok) {
		const data = await response.json();
		return data;
	} else {
		const errorText = await response.text();
		throw new Error(`Failed to update contact data: ${errorText}`);
	}
}; // Hook to use fetch user data


const useFetchUserData = () => {
	const [userData, setUserData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const fetchData = useCallback(async (email, fields) => {
		setLoading(true);
		setError(null);

		try {
			const data = await fetchUserData(email, fields);
			setUserData(data);
		} catch (err) {
			setError(err.message);
		} finally {
			setLoading(false);
		}
	}, []);
	return {
		userData,
		loading,
		error,
		fetchData
	};
}; // Hook to update user data


const useUpdateUserData = () => {
	const [updateStatus, setUpdateStatus] = useState(null);
	const [updateLoading, setUpdateLoading] = useState(false);
	const [updateError, setUpdateError] = useState(null);
	const updateData = useCallback(async (email, fields) => {
		setUpdateLoading(true);
		setUpdateError(null);

		try {
			const data = await updateUserData(email, fields);
			setUpdateStatus('Update successful!');
			return data;
		} catch (err) {
			setUpdateError(err.message);
			setUpdateStatus(`Update failed: ${err.message}`);
			throw err;
		} finally {
			setUpdateLoading(false);
		}
	}, []);
	return {
		updateStatus,
		updateLoading,
		updateError,
		updateData
	};
}; // Hook to get companies data


const useGetCompaniesData = () => {
	const [companiesData, setCompaniesData] = useState(null);
	const [companiesLoading, setCompaniesLoading] = useState(false);
	const [companiesError, setCompaniesError] = useState(null);
	const fetchCompaniesData = useCallback(async (email, fields) => {
		setCompaniesLoading(true);
		setCompaniesError(null);

		try {
			const data = await getCompaniesData(email, fields);
			setCompaniesData(data);
		} catch (err) {
			setCompaniesError(err.message);
		} finally {
			setCompaniesLoading(false);
		}
	}, []);
	return {
		companiesData,
		companiesLoading,
		companiesError,
		fetchCompaniesData
	};
}; // Hook to update contact data by ID


const useUpdateContactDataById = () => {
	const [updateStatus, setUpdateStatus] = useState(null);
	const [updateLoading, setUpdateLoading] = useState(false);
	const [updateError, setUpdateError] = useState(null);
	const updateDataById = useCallback(async (contactId, fields) => {
		setUpdateLoading(true);
		setUpdateError(null);

		try {
			const data = await updateContactDataById(contactId, fields);
			setUpdateStatus('Update successful!');
			return data;
		} catch (err) {
			setUpdateError(err.message);
			setUpdateStatus(`Update failed: ${err.message}`);
			throw err;
		} finally {
			setUpdateLoading(false);
		}
	}, []);
	return {
		updateStatus,
		updateLoading,
		updateError,
		updateDataById
	};
};

export { useFetchUserData, useUpdateUserData, useGetCompaniesData, useUpdateContactDataById }; // Default export

export default {
	useFetchUserData,
	useUpdateUserData,
	useGetCompaniesData,
	useUpdateContactDataById
};