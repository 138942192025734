import React, { useEffect, useState } from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Link, Box, Input, Button, Section } from "@quarkly/widgets";
import { useUser } from './FirebaseHooks';
import UserAccountForm from './UserAccountForm';
import { useFetchUserData, useUpdateUserData } from './CrmUtils';
const defaultProps = {
	"padding": "120px 0 120px 0",
	"lg-margin": "0 0 0 0",
	"lg-padding": "80px 0 80px 0",
	"quarkly-title": "UserProfile",
	"background": "#ffffff"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"quarkly-title": "Header"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--headline1",
			"text-align": "center",
			"children": "User Account"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"align-items": "center",
			"position": "sticky",
			"top": 0,
			"left": 0,
			"right": 0,
			"justify-content": "space-around",
			"sm-display": "grid",
			"opacity": "0.5"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"href": "#user-details",
			"color": "#000000",
			"children": "User details"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"href": "#login-methods",
			"color": "#000000",
			"children": "Login methods"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"padding": "0px 120px 0px 120px",
			"lg-flex-direction": "column",
			"lg-padding": "0px 0 0px 0",
			"align-self": "center"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "grid",
			"justify-content": "space-between",
			"margin": "0px 0px 40px 0px",
			"md-flex-direction": "column",
			"sm-grid-template-columns": "1fr",
			"quarkly-title": "User details",
			"id": "user-details",
			"grid-template-columns": "1fr"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"font": "--headline2",
			"color": "--darkL1",
			"lg-margin": "0px 0px 20px 0px",
			"margin": "0px 0 25px 0",
			"md-width": "100%",
			"md-margin": "0 0 20px 0",
			"sm-text-align": "center",
			"sm-margin": "0 0 30px 0",
			"children": "User details"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"md-margin": "0 0 25px 0",
			"md-width": "100%",
			"display": "grid",
			"grid-template-columns": "repeat(2, 1fr)",
			"grid-gap": "16px 24px",
			"margin": "0px 5px 0px 5px",
			"justify-items": "stretch"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 7px 0px",
			"color": "--darkL1",
			"children": "Name",
			"font": "--base",
			"disabled": true
		}
	},
	"input": {
		"kind": "Input",
		"props": {
			"display": "block",
			"placeholder-color": "LightGray",
			"placeholder": "Wavedropper LTD",
			"type": "text",
			"disabled": true
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 7px 0px",
			"color": "--darkL1",
			"children": "Position",
			"font": "--base"
		}
	},
	"input1": {
		"kind": "Input",
		"props": {
			"display": "block",
			"placeholder-color": "LightGray",
			"background": "white",
			"type": "text",
			"placeholder": "Owner"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--darkL1",
			"children": "Phone number",
			"font": "--base"
		}
	},
	"input2": {
		"kind": "Input",
		"props": {
			"display": "block",
			"placeholder-color": "LightGray",
			"background": "white",
			"placeholder": "+447123456789",
			"type": "tel",
			"pattern": "((\\+\\d{1,2}\\s?)?1?[\\.\\-\\s]?\\(?\\d{3}\\)?[\\.\\-\\s]?\\d{3}[\\.\\-\\s]?\\d{4})"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--darkL1",
			"children": "Email address",
			"font": "--base"
		}
	},
	"input3": {
		"kind": "Input",
		"props": {
			"display": "block",
			"placeholder-color": "LightGray",
			"type": "email",
			"placeholder": "hello@wavedropper.com",
			"disabled": true
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"padding": "0px 120px 0px 120px",
			"lg-flex-direction": "column",
			"lg-padding": "0px 0 0px 0",
			"align-self": "center",
			"quarkly-title": "Login methods",
			"id": "login-methods"
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "grid",
			"justify-content": "space-between",
			"margin": "0px 0px 40px 0px",
			"md-flex-direction": "column",
			"sm-grid-template-columns": "1fr",
			"grid-template-columns": "1fr"
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			"font": "--headline2",
			"color": "--darkL1",
			"lg-margin": "0px 0px 20px 0px",
			"margin": "0px 0 25px 0",
			"md-width": "100%",
			"md-margin": "0 0 20px 0",
			"sm-text-align": "center",
			"sm-margin": "0 0 30px 0",
			"children": "Login methods"
		}
	},
	"box7": {
		"kind": "Box",
		"props": {
			"md-margin": "0 0 25px 0",
			"md-width": "100%",
			"display": "grid",
			"grid-gap": "16px 24px",
			"margin": "0px 5px 0px 5px",
			"justify-items": "start",
			"background": "rgba(0, 0, 0, 0) url(https://images.unsplash.com/photo-1512279912738-2a7a890c4875?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000) center/contain repeat scroll padding-box"
		}
	},
	"text7": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"justify-self": "center",
			"align-self": "center",
			"color": "--platinum",
			"background": "--color-primary",
			"padding": "5px 15px 5px 15px",
			"font": "--lead",
			"children": "Firebase Integration"
		}
	},
	"box8": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "grid",
			"align-items": "center",
			"justify-items": "center",
			"quarkly-title": "Save button"
		}
	},
	"button": {
		"kind": "Button",
		"props": {
			"font": "--lead"
		}
	}
};

const UserProfile = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	const {
		userData,
		loading,
		error,
		fetchData
	} = useFetchUserData();
	const {
		updateStatus,
		updateLoading,
		updateError,
		updateData
	} = useUpdateUserData();
	const [user] = useUser();
	const [username, setUsername] = useState('');
	const [email, setEmail] = useState('');
	useEffect(() => {
		if (user) {
			console.log(user.displayName);
			setUsername(user.displayName);
			setEmail(user.email);
		}
	}, [user]);
	useEffect(() => {
		if (email) {
			handleFetchContactDetailsByEmail(email);
		}
	}, [email]);
	useEffect(() => {
		if (userData) {
			console.log("userData", userData);
		}
	}, [userData]);

	const handleFetchContactDetailsByEmail = email => {
		console.log("fetching contact's details...");
		const fields = ['id', 'name', 'midname', 'lastname', 'position', 'description', 'email', 'phones', 'companies'];
		console.log(email);
		fetchData(email, fields);
	};

	const handleUpdateContactByEmail = async (email, fields) => {
		console.log("updating contact...");
		console.log(fields);

		try {
			await updateData(email, fields);
		} catch (error) {
			console.error(error);
		}
	};

	return <>
		{loading && <p>
			<br />
			<br />
			<br />
			<br />
			Loading...
		</p>}
		{error && <p>
			Error:
			{error}
		</p>}
		{userData && <UserAccountForm
			key={""}
			override={override}
			rest={rest}
			userData={userData}
			setUserData={handleUpdateContactByEmail}
			updateLoading={updateLoading}
		/>}
	</>;
	;
};

Object.assign(UserProfile, { ...Section,
	defaultProps,
	overrides
});
export default UserProfile;