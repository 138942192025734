import { useState, useEffect } from 'react';
import { getFirestore, collection, query, orderBy, onSnapshot, doc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirebase } from './FirebaseConfig'; // the useFirebase hook is a wrapper over useEffect.
// The function passed to useFirebase will be run after
// firebase is initialized.

export const useFirebase = (cb, deps = []) => {
	const firebase = getFirebase();
	useEffect(() => {
		if (!firebase) return;
		return cb(firebase);
	}, [firebase, ...deps]);
}; // The useLoadingValue hook is an auxiliary hook to control the state of values.
// In addition to value, loading, error returns auxiliary
// functions success and failure to set value/error.

const useLoadingValue = () => {
	const [value, setValue] = useState(undefined);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(undefined);

	const success = res => {
		setLoading(false);
		setValue(res);
	};

	const failure = res => {
		setLoading(false);
		setError(res);
	};

	return {
		value,
		loading,
		error,
		success,
		failure
	};
}; // the useUser hook is used to sign up for user authorization


export const useUser = () => {
	const {
		success,
		...state
	} = useLoadingValue();
	useFirebase(firebase => {
		// Get an instance of FirebaseAuth
		const auth = getAuth(firebase); // Sign up for user authorization changes

		const unsubscribe = onAuthStateChanged(auth, success); // unsubscribe (reset the effect)

		return () => unsubscribe();
	});
	const {
		value,
		loading
	} = state;
	return [value, loading];
}; // the useAuth hook is used to get an instance of FirebaseAuth

export const useAuth = () => {
	const [auth, setAuth] = useState();
	useFirebase(firebase => {
		setAuth(getAuth(firebase));
	});
	return auth;
}; // the useFirestore hook is used to get an instance of Firestore

export const useFirestore = () => {
	const [db, setDb] = useState();
	useFirebase(firebase => {
		setDb(getFirestore(firebase));
	});
	return db;
};
export default {};