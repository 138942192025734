import React, { useEffect, useRef, useState } from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Section, Box, Icon, Image } from "@quarkly/widgets";
import Navigation from "./Navigation";
import getTabs from "./Tabs";
import { motion, AnimatePresence, useCycle } from "framer-motion";
import { IoMdClose, IoMdMenu, IoIosBusiness } from "react-icons/io";
const MotionBox = motion(Box);
const MotionIcon = motion(Icon);
const defaultProps = {
	//"min-height": "800px",
	"width": "",
	"height": "100vh",
	"overflow-x": "hidden",
	"overflow-y": "hidden",
	"min-height": "600px"
};
const overrides = {
	"navigation": {
		"kind": "Navigation",
		"props": {
			"grid-gap": "16px",
			"justify-content": "start",
			"margin": "30px 0px 0 0px",
			"height": "100%"
		},
		"overrides": {
			"icon-link": {
				"props": {
					"position": "relative",
					"z-index": "10",
					"color": "--platinum"
				}
			}
		}
	},
	"MenuToggleOpenIcon": {
		"props": {
			"category": "io",
			"icon": IoMdClose,
			"color": "--light",
			"opacity": "0.8"
		}
	},
	"SectionContent": {
		"props": {
			"overflow-x": "hidden",
			"overflow-y": "hidden"
		}
	},
	"MenuToggleIcon": {
		"props": {
			"category": "io",
			"icon": IoMdMenu,
			"position": "fixed",
			"z-index": "1"
		}
	},
	"MenuToggleCosedIcon": {
		"props": {
			"category": "io",
			"icon": IoMdMenu,
			"color": "--light"
		}
	},
	"SidebarWrapper": {
		"props": {}
	},
	"TabsWrapper": {
		"props": {
			"position": "absolute",
			"left": 0,
			"right": 0,
			"top": 0
		}
	},
	"MenuToggleClosedIcon": {
		"props": {
			"category": "io",
			"icon": IoMdMenu,
			"opacity": "0.8"
		}
	},
	"icon-link": {
		"props": {
			"category": "io",
			"icon": IoIosBusiness
		}
	},
	"SideBarWrapper": {
		"props": {
			"background": "--color-primary",
			"overflow-x": "hidden",
			"overflow-y": "hidden",
			"position": "fixed",
			"opacity": "0.95",
			"sm-mix-blend-mode": "multiply"
		}
	}
};
const sidebar = {
	open: (height = 1000) => ({
		//background: '--color-secondary',
		clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
		transition: {
			type: "spring",
			stiffness: 20,
			restDelta: 2
		}
	}),
	closed: {
		clipPath: "circle(30px at 40px 40px)",
		//background: '--color-secondary',
		transition: {
			delay: 0.5,
			type: "spring",
			stiffness: 400,
			damping: 40
		}
	}
};
const iconVariants = {
	visible: {
		opacity: 1,
		color: "#00ff00"
	},
	hidden: {
		opacity: 0,
		color: "#0000ff"
	}
};

const MainWrapper = ({
	userName,
	override,
	...props
}) => {
	const {
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	const [isOpen, toggleOpen] = useCycle(false, true);
	const [selectedTab, setSelectedTab] = useState(0);

	const handleTabSelection = (tab, e) => {
		//e.stopEventPropagation();
		setSelectedTab(tab);
		console.log("handleTabSelection", tab);
	};

	useEffect(() => {
		console.log("Tab Selected", selectedTab);
	}, [selectedTab]);
	return <Section {...rest}>
		<Image {...override('topLeftLogo')} />
		<Override slot="SectionContent" flex-direction="row" display="grid" grid-template-columns="1fr 2fr" />
		<Box style={{
			position: "fixed",
			zIndex: "10",
			color: "#fff",
			top: 9,
			left: 9,
			padding: "20px",
			borderRadius: "50%"
		}} onClick={toggleOpen}>
			<AnimatePresence>
				<MotionIcon {...override(isOpen ? 'MenuToggleOpenIcon' : 'MenuToggleClosedIcon')} />
			</AnimatePresence>
		</Box>
		<MotionBox
			{...override('SideBarWrapper')}
			style={{
				position: "fixed",
				left: "0",
				top: "0",
				zIndex: 1
			}}
			height="100vh"
			width="300px"
			initial={false}
			animate={isOpen ? "open" : "closed"}
			transition={{
				duration: 0.3
			}}
			variants={sidebar}
		>
			<Navigation isOpen={isOpen} onClick={toggleOpen} selectTab={handleTabSelection} {...override("navigation")}></Navigation>
		</MotionBox>
		<AnimatePresence>
			<MotionBox
				{...override('TabsWrapper')}
				initial={{
					opacity: 0
				}}
				transition={{
					duration: 1
				}}
				animate={{
					opacity: 1
				}}
				exit={{
					opacity: 0
				}}
				key={selectedTab ? `tab-no-${selectedTab}` : "empty"}
			>
				{getTabs()[selectedTab]}
			</MotionBox>
		</AnimatePresence>
	</Section>;
};

Object.assign(MainWrapper, { ...Section,
	defaultProps,
	overrides
});
export default MainWrapper;