import React from "react";
import { useOverrides } from "@quarkly/components";
import { Link, Box, List, Text, Icon } from "@quarkly/widgets";
import { motion, AnimatePresence } from "framer-motion";
import { FaBuilding, FaTasks, FaUserTie } from "react-icons/fa";
import { GiGears } from "react-icons/gi";
const MotionBox = motion(Box);
const MotionList = motion(List);
const defaultProps = {
	"position": "fixed",
	"top": "50px",
	"left": "0",
	"width": "300px",
	"grid-gap": "16px",
	"justify-content": "start",
	"margin": "0 0px 0 0px",
	"height": "100vh"
};
const overrides = {
	"link": {
		"kind": "Link",
		"props": {
			"href": "#",
			"text-decoration": "none",
			"padding": "15px 15px 15px 15px",
			"font": "small-caps 600 20px --fontFamily-googlePoppins",
			"children": "Business Profile",
			"position": "relative",
			"color": "--platinum"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"href": "#",
			"text-decoration": "none",
			"padding": "15px 15px 15px 15px",
			"font": "small-caps 600 20px --fontFamily-googlePoppins",
			"children": "Your Account",
			"position": "relative",
			"color": "--platinum"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"href": "#",
			"text-decoration": "none",
			"padding": "15px 15px 15px 15px",
			"font": "small-caps 600 20px --fontFamily-googlePoppins",
			"children": "Projects",
			"position": "relative",
			"color": "--platinum"
		}
	},
	"link3": {
		"kind": "Link",
		"props": {
			"href": "#",
			"text-decoration": "none",
			"letter-spacing": "1.5px",
			"padding": "15px 15px 15px 15px",
			"font": "small-caps 600 20px --fontFamily-googlePoppins",
			"children": "Customer Service",
			"position": "relative",
			"color": "--platinum"
		}
	},
	"LinkWrapper": {
		"props": {
			"margin": "30px 0px 30px 0px"
		}
	},
	"LinkWrapper-link": {
		"props": {
			"display": "flex"
		}
	},
	"LinkWrapper-link3": {
		"props": {
			"display": "flex"
		}
	},
	"LinkWrapper-link2": {
		"props": {
			"display": "flex"
		}
	},
	"LinkWrapper-link1": {
		"props": {
			"display": "flex"
		}
	},
	"icon-link": {
		"props": {
			"align-self": "center",
			"color": "--platinum",
			"category": "fa",
			"icon": FaBuilding
		}
	},
	"icon-link2": {
		"props": {
			"align-self": "center",
			"color": "--platinum",
			"category": "gi",
			"icon": GiGears
		}
	},
	"icon-link3": {
		"props": {
			"align-self": "center",
			"color": "--platinum",
			"category": "fa",
			"icon": FaTasks
		}
	},
	"icon-link1": {
		"props": {
			"align-self": "center",
			"category": "fa",
			"icon": FaUserTie,
			"color": "--platinum"
		}
	}
};
const navVariants = {
	open: {
		transition: {
			staggerChildren: 0.07,
			delayChildren: 0.3
		}
	},
	closed: {
		transition: {
			staggerChildren: 0.05,
			staggerDirection: -1
		}
	}
};
const menuItemVariants = {
	open: {
		y: 0,
		opacity: 1,
		transition: {
			y: {
				stiffness: 1000,
				velocity: -100
			}
		}
	},
	closed: {
		y: 50,
		opacity: 0,
		transition: {
			y: {
				stiffness: 1000
			}
		}
	}
};

const Navigation = ({
	toggleOpen,
	selectTab,
	isOpen,
	...props
}) => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	const linkOverrides = ["link", "link1", "link2", "link3"];
	return <MotionList
		as="ul"
		style={{
			listStyleType: "none"
		}}
		variants={navVariants}
		animate={isOpen ? "open" : "closed"}
		{...rest}
	>
		{linkOverrides.map((linkOverrideKey, index) => <MotionBox
			{...override(`LinkWrapper-${linkOverrideKey}`)}
			as="li"
			variants={menuItemVariants}
			whileHover={{
				scale: 1.1
			}}
			whileTap={{
				scale: 0.95
			}}
			key={linkOverrideKey}
		>
			<Icon {...override(`icon-${linkOverrideKey}`)} />
			<Link onClick={e => selectTab(index, e)} {...override(linkOverrideKey)} />
		</MotionBox>)}
	</MotionList>;
};

Object.assign(Navigation, { ...Box,
	defaultProps,
	overrides
});
export default Navigation;