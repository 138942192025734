import React from "react";
import BusinessProfile from "./BusinessProfile";
import UserProfile from "./UserProfile";
import Projects from "./Projects";
import CustomerService from "./CustomerService";

const getTabs = () => {
	return [<BusinessProfile />, <UserProfile />, <Projects />, <CustomerService />];
};

export default getTabs;