import React, { useState, useEffect } from 'react';
import axios from 'axios';
import atomize from "@quarkly/atomize";
import { useUser } from './FirebaseHooks'; // Your custom Firebase hook

const PlanfixComponent = ({
	apiUrl,
	apiToken,
	...props
}) => {
	const [user] = useUser();
	const [email, setEmail] = useState('');
	const [contactId, setContactId] = useState(null);
	const [contactInfo, setContactInfo] = useState(null);
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		if (user) {
			setEmail(user.email);
		}
	}, [user]);
	useEffect(() => {
		console.log("Contact info: ", contactInfo);
	}, [contactInfo]);

	const searchContactByEmail = async email => {
		setLoading(true);

		try {
			const response = await axios.post(`${apiUrl}/contact/list`, {
				filters: [{
					type: 4005,
					// Assuming 4005 is the filter type for email
					operator: "equal",
					value: email
				}]
			}, {
				headers: {
					'Authorization': `Bearer ${apiToken}`,
					'Content-Type': 'application/json'
				}
			});
			const contact = response.data.contacts[0];
			setContactId(contact ? contact.id : null);
			return contact ? contact.id : null;
		} catch (error) {
			console.error("Error searching contact by email:", error);
			setLoading(false);
		}
	};

	const getContactInfo = async (contactId, fields = "id,name,midname,lastname,description,email,phones,companies,32529") => {
		try {
			const fieldsQuery = encodeURIComponent(fields);
			const response = await axios.get(`${apiUrl}/contact/${contactId}?fields=${fieldsQuery}`, //`${apiUrl}/customfield/contact/8?fields=6)}`,
			{
				headers: {
					'Authorization': `Bearer ${apiToken}`,
					'Content-Type': 'application/json'
				}
			});
			setContactInfo(response.data.contact);
			setLoading(false);
		} catch (error) {
			console.error("Error fetching contact info:", error);
			setLoading(false);
		}
	};

	const fetchContactInfo = async email => {
		const id = await searchContactByEmail(email);

		if (id) {
			await getContactInfo(id);
		}
	};

	useEffect(() => {
		if (email) {
			fetchContactInfo(email);
		}
	}, [email]);

	if (loading) {
		return <div {...props}>
			Loading...
		</div>;
	}

	return <div {...props}>
		      
		{user ? <div>
			          
			<h2>
				Welcome, 
				{user.displayName}
			</h2>
			          
			{contactInfo ? <div>
				              
				<h3>
					Contact Information
				</h3>
				              
				<p>
					ID: 
					{contactInfo.id}
				</p>
				              
				<p>
					Name: 
					{contactInfo.name}
				</p>
				              
				<p>
					Email: 
					{contactInfo.email}
				</p>
				              
				<p>
					Phone: 
					{contactInfo.phones[0].maskedNumber}
				</p>
				              
				{
					/* Add more contact information as needed */
				}
				            
			</div> : <p>
				Fetching contact information...
			</p>}
			        
		</div> : <div>
			Please log in to view your contact information.
		</div>}
		    
	</div>;
};

export default atomize(PlanfixComponent)({
	name: "PlanfixComponent",
	effects: {
		hover: ":hover"
	},
	normalize: true,
	mixins: true,
	description: {
		en: "PlanfixComponent — my awesome component"
	},
	propInfo: {
		apiUrl: {
			control: "input",
			description: "Base URL for Planfix API",
			category: "Planfix API",
			weight: 1
		},
		apiToken: {
			control: "input",
			description: "Bearer token for Planfix API",
			category: "Planfix API",
			weight: 1
		},
		yourCustomProps: {
			control: "input"
		}
	}
});