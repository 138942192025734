import React, { createContext, useContext, useState } from 'react';
import { useOverrides } from '@quarkly/components';
import { Box } from '@quarkly/widgets';
const MessageContext = createContext();
const defaultProps = {};
const overrides = {};

const MessageProvider = ({
	children,
	...props
}) => {
	const [messages, setMessages] = useState([{
		message: '',
		color: ''
	}]);
	const {
		override,
		rest
	} = useOverrides(props, overrides, defaultProps);

	const addMessage = (message, color = '#000000') => {
		setMessages([{
			message,
			color
		}]);
	};

	return <MessageContext.Provider value={{
		messages,
		addMessage
	}}>
		<Box {...rest}>
			{children}
		</Box>
	</MessageContext.Provider>;
};

const useMessage = () => {
	return useContext(MessageContext);
};

Object.assign(MessageProvider, {
	defaultProps,
	overrides
});
export { MessageProvider, useMessage };
export default MessageProvider;