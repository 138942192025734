import React, { useState, useEffect } from 'react';
import { useOverrides } from "@quarkly/components";
import { Box } from "@quarkly/widgets";
import { useFetchUserData, useUpdateUserData, useGetCompaniesData, useUpdateContactDataById } from './CrmUtils';
const defaultProps = {
	"min-width": "100px",
	"min-height": "100px"
};
const overrides = {};

const ExampleUsageOfCRMUtils = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	const {
		userData,
		loading,
		error,
		fetchData
	} = useFetchUserData();
	const {
		updateStatus,
		updateLoading,
		updateError,
		updateData
	} = useUpdateUserData();
	const {
		companiesData,
		companiesLoading,
		companiesError,
		fetchCompaniesData
	} = useGetCompaniesData();
	const {
		updateStatus: updateByIdStatus,
		updateLoading: updateByIdLoading,
		updateError: updateByIdError,
		updateDataById
	} = useUpdateContactDataById();

	const handleFetchContactDetailsByEmail = () => {
		console.log("fetching contact's details...");
		const email = 'zjb@digitalrifle.com';
		const fields = ['id', 'name', 'midname', 'lastname', 'position', 'description', 'email', 'phones', 'companies', 'facebook'];
		console.log(email);
		fetchData(email, fields);
	};

	useEffect(() => {
		console.log("userdata loaded", userData);
	}, [userData]);

	const handleUpdateContactByEmail = async () => {
		console.log("updating contact...");
		const email = 'zjb@digitalrifle.com';
		const fields = {
			name: 'Zsolt',
			midname: 'Jozsef',
			lastname: 'Bea',
			description: 'Updated Description',
			phones: [{
				number: '+447366204884',
				type: 1 // Ensure the type is also specified as expected by the CRM

			}]
		};
		console.log(fields);

		try {
			await updateData(email, fields);
		} catch (error) {
			console.error(error);
		}
	};

	const handleFetchCompanies = () => {
		console.log("fetching companies...");
		const email = 'zjb@digitalrifle.com';
		const fields = ['id', 'name', 'address', 'contacts', 'site', 'description', '32529', '32531', '32533', '36117', '36283', '36287', '36289'];
		console.log(fields);
		fetchCompaniesData(email, fields);
	};

	const handleUpdateById = async () => {
		const contactId = 8;
		const fields = {
			name: 'Updated Name By ID',
			description: 'Updated Description by ID'
		};

		try {
			await updateDataById(contactId, fields);
		} catch (error) {
			console.error(error);
		}
	};

	return <Box {...override('ExampleUsageOfCRMUtils')}>
		      
		<button onClick={handleFetchContactDetailsByEmail}>
			Fetch User Data
		</button>
		      
		{loading && <p>
			Loading...
		</p>}
		      
		{error && <p>
			Error: 
			{error}
		</p>}
		      
		{userData && <pre>
			{JSON.stringify(userData, null, 2)}
		</pre>}
		      
		<button onClick={handleUpdateContactByEmail}>
			Update User Data
		</button>
		      
		{updateLoading && <p>
			Updating...
		</p>}
		      
		{updateError && <p>
			Update Error: 
			{updateError}
		</p>}
		      
		{updateStatus && <p>
			{updateStatus}
		</p>}
		      
		<button onClick={handleFetchCompanies}>
			Fetch Companies Data
		</button>
		      
		{companiesLoading && <p>
			Loading...
		</p>}
		      
		{companiesError && <p>
			Error: 
			{companiesError}
		</p>}
		      
		{companiesData && <pre>
			{JSON.stringify(companiesData, null, 2)}
		</pre>}
		      
      
		<button onClick={handleUpdateById}>
			Update Contact Data By ID
		</button>
		      
		{updateByIdLoading && <p>
			Updating contact data by ID...
		</p>}
		      
		{updateByIdError && <p>
			Update Error: 
			{updateByIdError}
		</p>}
		      
		{updateByIdStatus && <p>
			{updateByIdStatus}
		</p>}
		    
	</Box>;
};

Object.assign(ExampleUsageOfCRMUtils, { ...Box,
	defaultProps,
	overrides
});
export default ExampleUsageOfCRMUtils;