import React, { useEffect } from 'react';
import { useOverrides } from '@quarkly/components';
import { Box, Text } from '@quarkly/widgets';
import { useMessage } from './MessageProvider';
const defaultProps = {
	margin: '0px 0px 0px 0px'
};
const overrides = {};

const UserMessages = ({ ...props
}) => {
	const {
		rest
	} = useOverrides(props, overrides, defaultProps);
	const {
		messages
	} = useMessage();
	useEffect(() => {
		console.log("messages", messages);
		console.log(messages && messages[messages.length - 1].message === '' || messages.length === 0);
	}, [messages]);

	if (messages[messages.length - 1].message === '' || messages.length === 0) {
		return null;
	}

	const latestMessage = messages[messages.length - 1];
	return <Box margin="0" padding="0" {...rest}>
		<Text color={latestMessage.color}>
			{latestMessage.message}
		</Text>
	</Box>;
};

Object.assign(UserMessages, {
	defaultProps,
	overrides
});
export default UserMessages;