import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Link, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "120px 0 120px 0",
	"lg-margin": "0 0 0 0",
	"lg-padding": "80px 0 80px 0",
	"quarkly-title": "Projects",
	"background": "#ffffff",
	"overflow-x": "hidden",
	"overflow-y": "hidden"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"quarkly-title": "Header"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--headline1",
			"text-align": "center",
			"children": "All Projects"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"align-items": "center",
			"position": "sticky",
			"top": 0,
			"left": 0,
			"right": 0,
			"justify-content": "space-around",
			"sm-display": "grid"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"href": "#project1",
			"color": "#000000",
			"children": "Project #1"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"href": "#project2",
			"color": "#000000",
			"children": "Project #2"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"padding": "0px 120px 0px 120px",
			"lg-flex-direction": "column",
			"lg-padding": "0px 0 0px 0",
			"align-self": "center"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"justify-content": "space-between",
			"margin": "0px 0px 40px 0px",
			"md-flex-direction": "column",
			"sm-grid-template-columns": "1fr",
			"grid-template-columns": "repeat(2, 1fr)",
			"id": "project1"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"font": "--headline2",
			"color": "--darkL1",
			"lg-margin": "0px 0px 20px 0px",
			"margin": "0px 0 25px 0",
			"md-width": "100%",
			"md-margin": "0 0 20px 0",
			"sm-text-align": "center",
			"sm-margin": "0 0 30px 0",
			"children": "Project #1"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"md-margin": "0 0 25px 0",
			"md-width": "100%",
			"grid-gap": "16px 24px",
			"margin": "0px 5px 0px 5px",
			"grid-template-columns": "repeat(2, 1fr)",
			"justify-items": "stretch"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"justify-content": "space-between",
			"border-width": "0 0 1px 0",
			"border-style": "dotted",
			"border-radius": " "
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 7px 0px",
			"font": "--lead",
			"color": "--darkL1",
			"children": "Name"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 7px 0px",
			"font": "--lead",
			"color": "--darkL1",
			"children": "Client-Portal-001"
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"justify-content": "space-between",
			"border-width": "0 0 1px 0",
			"border-style": "dotted",
			"border-radius": " "
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 7px 0px",
			"font": "--lead",
			"color": "--darkL1",
			"children": "Added"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 7px 0px",
			"font": "--lead",
			"color": "--darkL1",
			"children": "07/04/2024"
		}
	},
	"box7": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"justify-content": "space-between",
			"border-width": "0 0 1px 0",
			"border-style": "dotted",
			"border-radius": " "
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 7px 0px",
			"font": "--lead",
			"color": "--darkL1",
			"children": "Status"
		}
	},
	"text7": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 7px 0px",
			"font": "--lead",
			"color": "--darkL1",
			"children": "In Progress"
		}
	},
	"box8": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"align-items": "center",
			"justify-content": "center"
		}
	},
	"text8": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--headline3",
			"text-align": "center",
			"children": "Notes"
		}
	},
	"box9": {
		"kind": "Box",
		"props": {
			"justify-content": "space-between",
			"border-width": "0 0 1px 0",
			"border-style": "dotted",
			"border-radius": " ",
			"md-display": "grid",
			"md-grid-gap": "16px",
			"display": "grid",
			"grid-gap": "16px"
		}
	},
	"box10": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"align-items": "center"
		}
	},
	"text9": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"children": "Initiated development of \"Firebase Login Feature\", aiming to enhance user experience and functionality. Currently in the design phase, with focus on usability and integration. Development and testing timelines are being established. Further updates will detail progress and expected release timeline."
		}
	},
	"box11": {
		"kind": "Box",
		"props": {
			"justify-items": "stretch",
			"display": "grid",
			"grid-template-columns": "1fr 1fr",
			"font": "italic 400 16px/1.5 --fontFamily-sansTrebuchet"
		}
	},
	"text10": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 7px 0px",
			"color": "--darkL1",
			"text-align": "left",
			"children": "Submitted by: Balazs"
		}
	},
	"text11": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 7px 0px",
			"color": "--darkL1",
			"text-align": "right",
			"children": "13:12 07/04/2024"
		}
	},
	"box12": {
		"kind": "Box",
		"props": {
			"justify-content": "space-between",
			"border-width": "0 0 1px 0",
			"border-style": "dotted",
			"border-radius": " ",
			"md-grid-gap": "16px",
			"display": "grid",
			"grid-gap": "16px"
		}
	},
	"box13": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"align-items": "center"
		}
	},
	"text12": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"children": <>
				We've identified the issue reported (ref:65465657) and are currently implementing a fix. Expected resolution is imminent.{" "}
			</>
		}
	},
	"box14": {
		"kind": "Box",
		"props": {
			"justify-items": "stretch",
			"display": "grid",
			"grid-template-columns": "1fr 1fr",
			"font": "italic 400 16px/1.5 --fontFamily-sansTrebuchet"
		}
	},
	"text13": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 7px 0px",
			"color": "--darkL1",
			"text-align": "left",
			"children": "Submitted by: Balazs"
		}
	},
	"text14": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 7px 0px",
			"color": "--darkL1",
			"text-align": "right",
			"children": "13:12 07/04/2024"
		}
	},
	"box15": {
		"kind": "Box",
		"props": {
			"justify-content": "space-between",
			"border-width": "0 0 1px 0",
			"border-style": "dotted",
			"border-radius": " ",
			"md-display": "grid",
			"md-grid-gap": "16px",
			"display": "grid",
			"grid-gap": "16px"
		}
	},
	"box16": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"align-items": "center"
		}
	},
	"text15": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"children": "Identified root cause and currently implementing fix. Progress is on track for a timely update deployment. Testing is underway to ensure full resolution without side effects. Update to follow upon completion."
		}
	},
	"box17": {
		"kind": "Box",
		"props": {
			"justify-items": "stretch",
			"display": "grid",
			"grid-template-columns": "1fr 1fr",
			"font": "italic 400 16px/1.5 --fontFamily-sansTrebuchet"
		}
	},
	"text16": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 7px 0px",
			"color": "--darkL1",
			"text-align": "left",
			"children": "Submitted by: Balazs"
		}
	},
	"text17": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 7px 0px",
			"color": "--darkL1",
			"text-align": "right",
			"children": "14:20 07/04/2024"
		}
	},
	"box18": {
		"kind": "Box",
		"props": {
			"justify-content": "space-between",
			"border-width": "0 0 1px 0",
			"border-style": "dotted",
			"border-radius": " ",
			"md-display": "grid",
			"md-grid-gap": "16px",
			"display": "grid",
			"grid-gap": "16px"
		}
	},
	"box19": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"align-items": "center"
		}
	},
	"text18": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"children": "Root cause established. Fix development in progress, with targeted resolution steps being applied. Quality assurance phase next, ensuring the solution meets our standards. Final deployment date will be announced post-testing success."
		}
	},
	"box20": {
		"kind": "Box",
		"props": {
			"justify-items": "stretch",
			"display": "grid",
			"grid-template-columns": "1fr 1fr",
			"font": "italic 400 16px/1.5 --fontFamily-sansTrebuchet"
		}
	},
	"text19": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 7px 0px",
			"color": "--darkL1",
			"text-align": "left",
			"children": "Submitted by: Balazs"
		}
	},
	"text20": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 7px 0px",
			"color": "--darkL1",
			"text-align": "right",
			"children": "14:50 07/04/2024"
		}
	},
	"box21": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"justify-content": "space-between",
			"margin": "0px 0px 40px 0px",
			"md-flex-direction": "column",
			"sm-grid-template-columns": "1fr",
			"grid-template-columns": "repeat(2, 1fr)",
			"id": "project2"
		}
	},
	"text21": {
		"kind": "Text",
		"props": {
			"font": "--headline2",
			"color": "--darkL1",
			"lg-margin": "0px 0px 20px 0px",
			"margin": "0px 0 25px 0",
			"md-width": "100%",
			"md-margin": "0 0 20px 0",
			"sm-text-align": "center",
			"sm-margin": "0 0 30px 0",
			"children": "Project #2"
		}
	},
	"box22": {
		"kind": "Box",
		"props": {
			"md-margin": "0 0 25px 0",
			"md-width": "100%",
			"grid-gap": "16px 24px",
			"margin": "0px 5px 0px 5px",
			"grid-template-columns": "repeat(2, 1fr)",
			"justify-items": "stretch"
		}
	},
	"box23": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"justify-content": "space-between",
			"border-width": "0 0 1px 0",
			"border-style": "dotted",
			"border-radius": " "
		}
	},
	"text22": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 7px 0px",
			"font": "--lead",
			"color": "--darkL1",
			"children": "Name"
		}
	},
	"text23": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 7px 0px",
			"font": "--lead",
			"color": "--darkL1",
			"children": "Client-Portal-002"
		}
	},
	"box24": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"justify-content": "space-between",
			"border-width": "0 0 1px 0",
			"border-style": "dotted",
			"border-radius": " "
		}
	},
	"text24": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 7px 0px",
			"font": "--lead",
			"color": "--darkL1",
			"children": "Added"
		}
	},
	"text25": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 7px 0px",
			"font": "--lead",
			"color": "--darkL1",
			"children": "07/04/2024"
		}
	},
	"box25": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"justify-content": "space-between",
			"border-width": "0 0 1px 0",
			"border-style": "dotted",
			"border-radius": " "
		}
	},
	"text26": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 7px 0px",
			"font": "--lead",
			"color": "--darkL1",
			"children": "Status"
		}
	},
	"text27": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 7px 0px",
			"font": "--lead",
			"color": "--darkL1",
			"children": "Upcoming"
		}
	},
	"box26": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"align-items": "center",
			"justify-content": "center"
		}
	},
	"text28": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--headline3",
			"text-align": "center",
			"children": "Notes"
		}
	},
	"box27": {
		"kind": "Box",
		"props": {
			"justify-content": "space-between",
			"border-width": "0 0 1px 0",
			"border-style": "dotted",
			"border-radius": " ",
			"display": "grid",
			"grid-gap": "16px"
		}
	},
	"box28": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"align-items": "center"
		}
	},
	"text29": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"children": "Initiated development of \"Firebase Login Feature\", aiming to enhance user experience and functionality. Currently in the design phase, with focus on usability and integration. Development and testing timelines are being established. Further updates will detail progress and expected release timeline."
		}
	},
	"box29": {
		"kind": "Box",
		"props": {
			"justify-items": "stretch",
			"display": "grid",
			"grid-template-columns": "1fr 1fr",
			"font": "italic 400 16px/1.5 --fontFamily-sansTrebuchet"
		}
	},
	"text30": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 7px 0px",
			"color": "--darkL1",
			"text-align": "left",
			"children": "Submitted by: Balazs"
		}
	},
	"text31": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 7px 0px",
			"color": "--darkL1",
			"text-align": "right",
			"children": "13:12 07/04/2024"
		}
	},
	"box30": {
		"kind": "Box",
		"props": {
			"justify-content": "space-between",
			"border-width": "0 0 1px 0",
			"border-style": "dotted",
			"border-radius": " ",
			"display": "grid",
			"grid-gap": "16px"
		}
	},
	"box31": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"align-items": "center"
		}
	},
	"text32": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"children": <>
				We've identified the issue reported (ref:65465657) and are currently implementing a fix. Expected resolution is imminent.{" "}
			</>
		}
	},
	"box32": {
		"kind": "Box",
		"props": {
			"justify-items": "stretch",
			"display": "grid",
			"grid-template-columns": "1fr 1fr",
			"font": "italic 400 16px/1.5 --fontFamily-sansTrebuchet"
		}
	},
	"text33": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 7px 0px",
			"color": "--darkL1",
			"text-align": "left",
			"children": "Submitted by: Balazs"
		}
	},
	"text34": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 7px 0px",
			"color": "--darkL1",
			"text-align": "right",
			"children": "13:12 07/04/2024"
		}
	},
	"box33": {
		"kind": "Box",
		"props": {
			"justify-content": "space-between",
			"border-width": "0 0 1px 0",
			"border-style": "dotted",
			"border-radius": " ",
			"display": "grid",
			"grid-gap": "16px"
		}
	},
	"box34": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"align-items": "center"
		}
	},
	"text35": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"children": "Identified root cause and currently implementing fix. Progress is on track for a timely update deployment. Testing is underway to ensure full resolution without side effects. Update to follow upon completion."
		}
	},
	"box35": {
		"kind": "Box",
		"props": {
			"justify-items": "stretch",
			"display": "grid",
			"grid-template-columns": "1fr 1fr",
			"font": "italic 400 16px/1.5 --fontFamily-sansTrebuchet"
		}
	},
	"text36": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 7px 0px",
			"color": "--darkL1",
			"text-align": "left",
			"children": "Submitted by: Balazs"
		}
	},
	"text37": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 7px 0px",
			"color": "--darkL1",
			"text-align": "right",
			"children": "14:20 07/04/2024"
		}
	},
	"box36": {
		"kind": "Box",
		"props": {
			"justify-content": "space-between",
			"border-width": "0 0 1px 0",
			"border-style": "dotted",
			"border-radius": " ",
			"display": "grid",
			"grid-gap": "16px"
		}
	},
	"box37": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"align-items": "center"
		}
	},
	"text38": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"children": "Root cause established. Fix development in progress, with targeted resolution steps being applied. Quality assurance phase next, ensuring the solution meets our standards. Final deployment date will be announced post-testing success."
		}
	},
	"box38": {
		"kind": "Box",
		"props": {
			"justify-items": "stretch",
			"display": "grid",
			"grid-template-columns": "1fr 1fr",
			"font": "italic 400 16px/1.5 --fontFamily-sansTrebuchet"
		}
	},
	"text39": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 7px 0px",
			"color": "--darkL1",
			"text-align": "left",
			"children": "Submitted by: Balazs"
		}
	},
	"text40": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 7px 0px",
			"color": "--darkL1",
			"text-align": "right",
			"children": "14:50 07/04/2024"
		}
	}
};

const Projects = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" display="grid" justify-items="stretch" />
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Box {...override("box1")}>
				<Link {...override("link")} />
				<Link {...override("link1")} />
			</Box>
		</Box>
		<Box {...override("box2")}>
			<Box {...override("box3")}>
				<Text {...override("text1")} />
				<Box {...override("box4")}>
					<Box {...override("box5")}>
						<Text {...override("text2")} />
						<Text {...override("text3")} />
					</Box>
					<Box {...override("box6")}>
						<Text {...override("text4")} />
						<Text {...override("text5")} />
					</Box>
					<Box {...override("box7")}>
						<Text {...override("text6")} />
						<Text {...override("text7")} />
					</Box>
					<Box {...override("box8")}>
						<Text {...override("text8")} />
					</Box>
					<Box {...override("box9")}>
						<Box {...override("box10")}>
							<Text {...override("text9")} />
						</Box>
						<Box {...override("box11")}>
							<Text {...override("text10")} />
							<Text {...override("text11")} />
						</Box>
					</Box>
					<Box {...override("box12")}>
						<Box {...override("box13")}>
							<Text {...override("text12")} />
						</Box>
						<Box {...override("box14")}>
							<Text {...override("text13")} />
							<Text {...override("text14")} />
						</Box>
					</Box>
					<Box {...override("box15")}>
						<Box {...override("box16")}>
							<Text {...override("text15")} />
						</Box>
						<Box {...override("box17")}>
							<Text {...override("text16")} />
							<Text {...override("text17")} />
						</Box>
					</Box>
					<Box {...override("box18")}>
						<Box {...override("box19")}>
							<Text {...override("text18")} />
						</Box>
						<Box {...override("box20")}>
							<Text {...override("text19")} />
							<Text {...override("text20")} />
						</Box>
					</Box>
				</Box>
			</Box>
			<Box {...override("box21")}>
				<Text {...override("text21")} />
				<Box {...override("box22")}>
					<Box {...override("box23")}>
						<Text {...override("text22")} />
						<Text {...override("text23")} />
					</Box>
					<Box {...override("box24")}>
						<Text {...override("text24")} />
						<Text {...override("text25")} />
					</Box>
					<Box {...override("box25")}>
						<Text {...override("text26")} />
						<Text {...override("text27")} />
					</Box>
					<Box {...override("box26")}>
						<Text {...override("text28")} />
					</Box>
					<Box {...override("box27")}>
						<Box {...override("box28")}>
							<Text {...override("text29")} />
						</Box>
						<Box {...override("box29")}>
							<Text {...override("text30")} />
							<Text {...override("text31")} />
						</Box>
					</Box>
					<Box {...override("box30")}>
						<Box {...override("box31")}>
							<Text {...override("text32")} />
						</Box>
						<Box {...override("box32")}>
							<Text {...override("text33")} />
							<Text {...override("text34")} />
						</Box>
					</Box>
					<Box {...override("box33")}>
						<Box {...override("box34")}>
							<Text {...override("text35")} />
						</Box>
						<Box {...override("box35")}>
							<Text {...override("text36")} />
							<Text {...override("text37")} />
						</Box>
					</Box>
					<Box {...override("box36")}>
						<Box {...override("box37")}>
							<Text {...override("text38")} />
						</Box>
						<Box {...override("box38")}>
							<Text {...override("text39")} />
							<Text {...override("text40")} />
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Projects, { ...Section,
	defaultProps,
	overrides
});
export default Projects;