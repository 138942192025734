import React, { useState, useEffect, useRef } from 'react';
import { Text, Link, Box, Input, Button, Section } from "@quarkly/widgets";
import { Override } from "@quarkly/components";
import atomize from "@quarkly/atomize";

const UserAccountForm = ({
	override,
	rest,
	userData,
	setUserData,
	updateLoading,
	...props
}) => {
	const [updatedData, setUpdatedData] = useState(userData);
	const formRef = useRef(null);
	useEffect(() => {
		console.log("UD", updatedData);
	}, [updatedData]);

	const handleChange = fieldName => e => {
		const {
			value
		} = e.target;
		setUpdatedData(prevData => ({ ...prevData,
			[fieldName]: value
		}));
	};

	const handlePhoneChange = index => e => {
		const {
			value
		} = e.target;
		setUpdatedData(prevData => ({ ...prevData,
			phones: prevData.phones.map((phone, i) => i === index ? { ...phone,
				maskedNumber: value,
				number: value
			} : phone)
		}));
	};

	const handleCustomFieldChange = fieldId => e => {
		const {
			value
		} = e.target;
		setUpdatedData(prevData => ({ ...prevData,
			customFieldData: prevData.customFieldData.map(cf => cf.field.id === fieldId ? { ...cf,
				value: value,
				stringValue: value
			} : cf)
		}));
	};

	const getCustomFieldValue = (data, fieldName) => {
		const customField = data.customFieldData.find(cf => cf.field.name === fieldName);
		console.log('cf', customField.value);
		return customField ? customField.value : null;
	};

	const handleSave = () => {
		setUserData(updatedData.email, updatedData);
	}; // Handle submit button click


	const handleSubmitClick = event => {
		event.preventDefault(); // Prevent default form submission

		if (formRef.current.checkValidity()) {
			handleSave();
		} else {
			formRef.current.reportValidity(); // Show validation errors
		}
	};

	return <Section {...rest}>
		    
		<form ref={formRef}>
			      
			<Override slot="SectionContent" display="grid" justify-items="stretch" />
			      
			<Box {...override("box")}>
				        
				<Text {...override("text")} />
				        
				<Box {...override("box1")}>
					          
					<Link {...override("link")} />
					          
					<Link {...override("link1")} />
					        
				</Box>
				      
			</Box>
			      
			<Box {...override("box2")}>
				        
				<Box {...override("box3")}>
					          
					<Text {...override("text1")} />
					          
					<Box {...override("box4")}>
						            
						<Text {...override("text2")} />
						            
						<Input defaultValue={`${updatedData.name || ""} ${updatedData.midname || ""} ${updatedData.lastname}`} {...override("input")} />
						            
						<Text {...override("text3")} />
						            
						<Input defaultValue={updatedData.position || ""} onChange={handleChange("position")} {...override("input1")} />
						            
						<Text {...override("text4")} />
						             
						<Input value={updatedData.phones.length > 0 ? updatedData.phones[0].maskedNumber : ""} onChange={handlePhoneChange(0)} {...override("input2")} />
						            
						<Text {...override("text5")} />
						            
						<Input defaultValue={updatedData.email || ""} {...override("input3")} />
						          
					</Box>
					        
				</Box>
				      
			</Box>
			      
			<Box {...override("box5")}>
				        
				<Box {...override("box6")}>
					          
					<Text {...override("text6")} />
					          
					<Box {...override("box7")}>
						            
						<Text {...override("text7")} />
						          
					</Box>
					        
				</Box>
				      
			</Box>
			      
			<Box {...override("box8")}>
				        
				<Button disabled={updateLoading} type="submit" onClick={handleSubmitClick} {...override("button")}>
					          Save Changes
        
				</Button>
				        
				<Text>
					{updateLoading && <p>
						Updating contact data by ID...
					</p>}
				</Text>
				      
			</Box>
			      
		</form>
		    
	</Section>;
};

export default atomize(UserAccountForm)({
	name: "BusinessProfileForm",
	effects: {
		hover: ":hover"
	},
	normalize: true,
	mixins: true,
	description: {
		// paste here description for your component
		en: "BusinessProfileForm — my awesome component"
	},
	propInfo: {}
});