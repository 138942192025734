import React, { useState, useEffect } from 'react';
import { useOverrides } from '@quarkly/components';
import { Box, Input, Button, Text } from '@quarkly/widgets';
import atomize from "@quarkly/atomize";
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { useMessage } from './MessageProvider';
const defaultProps = {
	"padding": "16px",
	"background": "#f9f9f9",
	"border-radius": "8px",
	"width": "300px",
	"max-width": "100%",
	"box-shadow": "0 2px 10px rgba(0,0,0,0.1)"
};
const overrides = {};

const SignupForm = ({
	override,
	...props
}) => {
	const {
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	const auth = getAuth();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [error, setError] = useState('');
	const [verificationSent, setVerificationSent] = useState(false);
	const {
		addMessage
	} = useMessage();

	const validatePassword = password => {
		const strongPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/;
		return strongPassword.test(password);
	};

	const handleSubmit = e => {
		e.preventDefault();

		if (!validatePassword(password)) {
			setError('Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, a number, and a special character.');
			return;
		}

		if (password !== confirmPassword) {
			setError('Passwords do not match.');
			return;
		}

		setError(''); // Submit form logic

		console.log("password ok");
		handleSignUp();
	};

	const handleSignUp = async () => {
		try {
			await createUserWithEmailAndPassword(auth, email, password);
			console.log("start verification");
			handleVerification(); // Call the verification function
		} catch (error) {
			setError(error.message);
		}
	};

	const handleVerification = async () => {
		try {
			await sendEmailVerification(auth.currentUser);
			console.log("verification sent");
			setVerificationSent(true);
		} catch (error) {
			setError(error.message);
		}
	};

	useEffect(() => {
		addMessage(error.replace("Firebase: ", ""), '#DA3E52');
	}, [error]);
	useEffect(() => {
		if (verificationSent) {
			addMessage('Check your mailbox to verify your email address.', '#2A9D8F');
		}
	}, [verificationSent]);
	return <Box {...rest} as="form" onSubmit={handleSubmit}>
		      
		{!verificationSent && <>
			          
			<Input {...override('EmailInput')} value={email} onChange={e => setEmail(e.target.value)} />
			          
			<Input {...override('PasswordInput')} value={password} onChange={e => setPassword(e.target.value)} />
			          
			<Input {...override('ConfirmPasswordInput')} value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
			          
			<Button {...override('SubmitButton')} />
			        
		</>}
		      
		{verificationSent && <Text {...override('Verify your email')} />}
		      
		{children}
		    
	</Box>;
};

export default atomize(SignupForm)({
	name: "SignupForm",
	effects: {
		hover: ":hover"
	},
	normalize: true,
	mixins: true,
	description: {
		en: "SignupForm — A form to sign up with email and password"
	},
	propInfo: {
		padding: {
			control: "input",
			type: "text",
			category: "Main",
			weight: 1
		},
		background: {
			control: "color",
			category: "Main",
			weight: 1
		},
		borderRadius: {
			control: "input",
			type: "text",
			category: "Main",
			weight: 1
		},
		width: {
			control: "input",
			type: "text",
			category: "Main",
			weight: 1
		},
		maxWidth: {
			control: "input",
			type: "text",
			category: "Main",
			weight: 1
		},
		boxShadow: {
			control: "input",
			type: "text",
			category: "Main",
			weight: 1
		}
	}
});