import React, { useState, useEffect, useRef } from 'react';
import { useOverrides, Override } from '@quarkly/components';
import MainWrapper from './MainWrapper';
import { Box, Text, Button, List, Icon, Image, Strong, Span } from '@quarkly/widgets';
import { useFetchUserData } from './CrmUtils';
import EmailLogin from './EmailLogin';
import { setDoc, doc } from 'firebase/firestore';
import { GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';
import { useUser, useAuth, useFirestore } from './FirebaseHooks';
import { useMessage } from './MessageProvider';
import UserMessages from './UserMessages';
import { IoMdClose, IoMdMenu, IoIosBusiness } from "react-icons/io";
import { FaArtstation } from "react-icons/fa";
const defaultProps = {
	"text-align": "center",
	"margin": "0px auto 0px auto"
};
const overrides = {
	'Google SignIn Button': {
		kind: 'Button',
		props: {
			children: 'Sign in with Google',
			"width": "100%",
			"margin": "0px 0px 25px 0px",
			"background": "--color-light",
			"color": "--primary",
			"border-width": "1px",
			"border-style": "solid",
			"border-color": "--color-primary",
			"padding": "8px 50px 8px 14px",
			"border-radius": "5px",
			"font": "--lead",
			"box-shadow": "--m"
		}
	},
	'Sign out Button': {
		kind: 'Button',
		props: {
			children: 'Sign out',
			"position": "absolute",
			"top": "60px",
			"right": "10px",
			"z-index": "10",
			"border-radius": "15px",
			"background": "--color-secondary"
		}
	},
	"navigation": {
		"kind": "Navigation",
		"props": {
			"grid-gap": "16px",
			"justify-content": "start",
			"margin": "30px 0px 0 0px",
			"height": "100%"
		},
		"overrides": {
			"icon-link": {
				"props": {
					"position": "relative",
					"z-index": "10",
					"color": "--platinum"
				}
			}
		}
	},
	"MenuToggleOpenIcon": {
		"props": {
			"category": "io",
			"icon": IoMdClose,
			"color": "--light",
			"opacity": "0.8"
		}
	},
	"SectionContent": {
		"props": {
			"overflow-x": "hidden",
			"overflow-y": "hidden"
		}
	},
	"MenuToggleIcon": {
		"props": {
			"category": "io",
			"icon": IoMdMenu,
			"position": "fixed",
			"z-index": "1"
		}
	},
	"MenuToggleCosedIcon": {
		"props": {
			"category": "io",
			"icon": IoMdMenu,
			"color": "--light"
		}
	},
	"SidebarWrapper": {
		"props": {}
	},
	"TabsWrapper": {
		"props": {
			"position": "absolute",
			"left": 0,
			"right": 0,
			"top": 0
		}
	},
	"MenuToggleClosedIcon": {
		"props": {
			"category": "io",
			"icon": IoMdMenu,
			"opacity": "0.8"
		}
	},
	"icon-link": {
		"props": {
			"category": "io",
			"icon": IoIosBusiness
		}
	},
	"SideBarWrapper": {
		"props": {
			"background": "--color-primary",
			"overflow-x": "hidden",
			"overflow-y": "hidden",
			"position": "fixed",
			"opacity": "0.95",
			"sm-mix-blend-mode": "multiply"
		}
	},
	"topLeftLogo": {
		"props": {
			"position": "fixed",
			"top": "9px",
			"left": "10px",
			"z-index": "10",
			"width": "60px",
			"src": "https://uploads.quarkly.io/65f854bd8e2e8e002179c15f/images/logo-final.svg?v=2024-04-07T23:00:58.096Z",
			"mix-blend-mode": "luminosity"
		}
	},
	"Signin Wrapper": {
		"props": {
			"padding": "15px 15px 15px 15px",
			"margin": "50px auto 0px auto",
			"max-width": "300px",
			"border-width": "1px",
			"border-style": "solid",
			"border-color": "--color-platinum",
			"box-shadow": "--xl",
			"border-radius": "15px"
		}
	},
	"Sign In Main Title": {
		"props": {
			"font": "--headline1",
			"children": "Wavedropper Client Portal"
		}
	},
	"Signin Outer Wrapper": {
		"props": {
			"margin": "0px auto 0px auto"
		}
	},
	"Title Wrapper": {
		"props": {
			"margin": "0px auto 0px auto",
			"max-width": "600px",
			"color": "--eerieBlack"
		}
	},
	"Sign In Descriptions": {
		"props": {
			"font": "--base",
			"text-align": "left",
			"children": "Here's how you can sign in:"
		}
	},
	"Li11": {
		"props": {
			"children": "Click on the \"Sign in with Google\" button."
		}
	},
	"Li12": {
		"props": {
			"children": "You will be redirected to the Google sign-in page."
		}
	},
	"Li13": {
		"props": {
			"children": "Enter your Google credentials and follow the prompts to log in."
		}
	},
	"First list title": {
		"props": {
			"text-align": "left",
			"font": "--base",
			"margin": "30px 0px 16px 0px",
			"children": <Strong>
				Google Account:
			</Strong>
		}
	},
	"First List": {
		"props": {
			"text-align": "left",
			"font": "--base"
		}
	},
	"Second list title": {
		"props": {
			"text-align": "left",
			"margin": "30px 0px 16px 0px",
			"font": "--base",
			"children": <Strong>
				Email Address:
			</Strong>
		}
	},
	"Li21": {
		"props": {
			"children": "Click on the \"Sign in with Email\" button."
		}
	},
	"Li22": {
		"props": {
			"children": "Enter your registered email address and password."
		}
	},
	"Li23": {
		"props": {
			"children": "Click \"Sign In\" to access your account."
		}
	},
	"Second List": {
		"props": {
			"text-align": "left",
			"flex-direction": "column",
			"font": "--base"
		}
	},
	"Email Login Wrapper": {
		"props": {
			"border-width": "none",
			"border-style": "none",
			"border-color": "none"
		},
		"overrides": {
			"Email Login Title": {
				"props": {
					"font": "--lead"
				}
			},
			"Show-Hide Password": {
				"props": {
					"background": "--color-greyD1"
				}
			},
			"Email Signin Btn": {
				"props": {
					"width": "100%",
					"background": "--color-light",
					"color": "--primary",
					"font": "--lead",
					"border-width": "1px",
					"border-style": "solid",
					"border-color": "--color-primary",
					"border-radius": "5px",
					"box-shadow": "--m",
					"children": "Sign in"
				}
			},
			"Password Wrapper": {
				"props": {}
			},
			"Email Btn Wrapper": {
				"props": {
					"position": "relative",
					"margin": "15px 0px 0px 0px"
				}
			},
			"Email icon": {
				"props": {
					"color": "--primary",
					"position": "absolute",
					"top": "10px",
					"right": "15px"
				}
			},
			"Sign Up link": {
				"props": {
					"color": "--primary",
					"font": "--base",
					"cursor": "pointer"
				}
			},
			"Email Signup Title": {
				"props": {
					"font": "--lead",
					"children": <Span font-weight="normal">
						Sign up with Email
					</Span>
				}
			}
		}
	},
	"Footer Wrapper": {
		"props": {
			"margin": "50px auto 0px auto",
			"max-width": "600px"
		}
	},
	"Sign In Welcome": {
		"props": {
			"font": "--lead",
			"children": "Welcome to the Wavedropper client portal!"
		}
	},
	"Google SignIn Button Wrapper": {
		"props": {
			"position": "relative",
			"z-index": "0",
			"border-width": "0 0 1px 0",
			"border-style": "solid",
			"border-color": "--color-platinum"
		}
	},
	"Google icon": {
		"props": {
			"position": "absolute",
			"z-index": "1",
			"src": "https://uploads.quarkly.io/65f854bd8e2e8e002179c15f/images/google_logo-google_icongoogle-512.webp?v=2024-06-15T13:45:26.296Z",
			"width": "32px",
			"top": "8px",
			"right": "10px"
		}
	},
	"Email icon": {
		"props": {
			"category": "fa",
			"icon": FaArtstation
		}
	},
	"G Button Container": {
		"props": {
			"position": "relative"
		}
	},
	"Access Denied": {
		"props": {}
	},
	"Access Denied Wrapper": {
		"props": {}
	}
}; // Initialize the Google auth provider

const googleProvider = new GoogleAuthProvider(); // When setting prompt: 'select_account' authorization server prompts the user 
// to select a user account. This allows a user who has multiple accounts at 
// the authorization server to select amongst the multiple accounts that 
// they may have current sessions for.

googleProvider.setCustomParameters({
	prompt: 'select_account'
}); // CommentForm component:
// Displays a form to post a comment, as well as an authorization button via Google account.

const CommentForm = ({ ...props
}) => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps); // Subscribes to the data of an authorized user

	const [user, loading] = useUser();
	const [isEmailVerfied, setIsEmailVerfied] = useState(false);
	useEffect(() => {
		if (user) {
			console.log("verifying user...");
		}

		if (user && isEmailVerfied) {
			console.log("User's email is verified.");
			console.log(user.displayName);
			handleFetchContactDetailsByEmail(user.email);
		} else {
			console.log("User's email is not verified!");
		}
	}, [user, isEmailVerfied]); // Get an instance of FirebaseAuth

	const auth = useAuth(); // Get an instance of Firestore

	const db = useFirestore(); // Declare text to store input state 

	const {
		addMessage
	} = useMessage();

	const verifyUserEmail = () => {
		auth.currentUser.reload();

		if (!auth.currentUser.emailVerified) {
			return false;
		}

		setIsEmailVerfied(true);
		return true;
	};

	const onSignInClick = async () => {
		if (!auth || !db) return; // Authorization via Google

		const {
			user
		} = await signInWithPopup(auth, googleProvider);
		console.log(user); // Add data about the user, so that afterwards

		await setDoc(doc(db, 'users', user.uid), {
			displayName: user.displayName,
			photoUrl: user.photoURL,
			email: user.email
		});
		verifyUserEmail();
	}; // Function for handling a press of the sign out button


	const onSignOutClick = () => {
		if (!auth) return;
		signOut(auth);
	}; // User validation against CRM


	const {
		userData: userCrmData,
		loading: crmLoading,
		error: crmError,
		fetchData: fetchCrmData
	} = useFetchUserData();
	const [isUserInCRM, setIsUserInCRM] = useState(false);
	useEffect(() => {
		console.log("Checking user in CRM", userCrmData);

		if (userCrmData) {
			setIsUserInCRM(true);
		} else {
			console.log("User isn't in CRM");
		}
	}, [userCrmData]);
	useEffect(() => {
		console.log('CRM ERROR: ', crmError);

		if (crmError) {
			onSignOutClick();
			addMessage('Access Denied! Contact support.', '#DA3E52');
		}
	}, [crmError]);

	const handleFetchContactDetailsByEmail = email => {
		console.log("fetching contact's details...");
		const fields = ['id'];
		console.log(email);
		fetchCrmData(email, fields);
	};

	return <Box {...rest}>
		{loading ? <Text>
			Loading...
		</Text> : user && isEmailVerfied && isUserInCRM && !crmError ? <Box {...override('Google Sign Out Button Wrapper')}>
			<Button {...override('Sign out Button')} onClick={onSignOutClick} />
			<MainWrapper override={override} {...override('MainWrapper')} />
		</Box> : <Box {...override('Signin Outer Wrapper')}>
			<Box {...override('Title Wrapper')}>
				<Text {...override('Sign In Main Title')} />
				<Text {...override('Sign In Welcome')} />
			</Box>
			<Box {...override('Signin Wrapper')}>
				<Box {...override('Google SignIn Button Wrapper')}>
					<Box {...override('G Button Container')}>
						<Button {...override('Google SignIn Button')} onClick={onSignInClick} />
						<Image {...override('Google icon')} />
					</Box>
				</Box>
				<EmailLogin {...override('Email Login Wrapper')} setIsEmailVerfied={setIsEmailVerfied} />
				<UserMessages override={override} {...override('UserMessages')} />
			</Box>
			{!crmError && <Box {...override('Footer Wrapper')}>
				<Text {...override('Sign In Descriptions')} />
				<Text {...override('First list title')} />
				<List {...override('First List')}>
					<Text {...override('Li11')} />
					<Text {...override('Li12')} />
					<Text {...override('Li13')} />
				</List>
				<Text {...override('Second list title')} />
				<List {...override('Second List')}>
					<Text {...override('Li21')} />
					<Text {...override('Li22')} />
					<Text {...override('Li23')} />
				</List>
			</Box>}
		</Box>}
	</Box>;
};

Object.assign(CommentForm, {
	title: "CommentForm",
	related: {
		attr: 'attr',
		styles: 'styles',
		components: 'components',
		override: 'override'
	},
	defaultProps,
	overrides
});
export default CommentForm;